import { menuConfigItems } from '../constants/menuConfigItems';
import { TRole } from '../pages/auth/interfaces';

const getMenuConfig = (userRoles: TRole[] | undefined, isAuthorized: boolean) => {
  if (isAuthorized) {
    if (userRoles === undefined) {
      return getDefaultMenu();
    } else if (userRoles[0] === 'ROLE_ADMIN') {
      return getAdminMenu();
    } else if (userRoles[0] === 'ROLE_MANAGER') {
      return getDefaultMenu();
    } else if (userRoles[0] === 'ROLE_VENDOR') {
      return getVendorMenu();
    } else if (userRoles[0] === 'ROLE_BUYER') {
      return getBuyerMenu();
    }
  } else {
    return getGuestMenu();
  }
};

const {
  profile,
  products,
  companies,
  users,
  legal,
  settings,
  logout,
  categories,
  catalog,
  adminTariffs,
  freelancersTariffs,
  providersTariffs,
  buyerProducts,
  vendorProducts,
  vendorCompany,
  vendorProfile,
  buyerProfile,
  providersBlog,
  buyerBlog,
  guestBlog,
  login,
} = menuConfigItems;

const getDefaultMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [profile, providersBlog, logout],
  },
});

const getBuyerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      buyerProducts,
      buyerProfile,
      // freelancersTariffs,
      buyerBlog,
      logout,
    ],
  },
});

const getAdminMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      products,
      companies,
      users,
      categories,
      catalog,
      adminTariffs,
      settings,
      legal,
      providersBlog,
      logout,
    ],
  },
});

const getVendorMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      vendorProducts,
      // vendorCompany,
      catalog,
      // providersTariffs,
      vendorProfile,
      providersBlog,
      logout,
    ],
  },
});

const getGuestMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [guestBlog, login],
  },
});

export default getMenuConfig;
