import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { TextField, Button, Grid, RadioGroup } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { ButtonWithLoader } from '../../components/ui/Buttons';

import { actions as authActions } from '../../store/ducks/auth.duck';
import { IAppState } from '../../store/rootDuck';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    background: '#fff',
    padding: '20px 40px',
    borderRadius: '5px',
  },
}));

const ForgotPassword: React.FC<WrappedComponentProps & TPropsFromRedux> = ({
  intl,
  clearRecoveryPassword,
  recoveryPasswordRequest,
  recoveryPasswordLoading,
  recoveryPasswordSuccess,
  recoveryPasswordErrors,
}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    if (recoveryPasswordSuccess || recoveryPasswordErrors) {
      enqueueSnackbar(
        recoveryPasswordSuccess
          ? intl.formatMessage({ id: 'AUTH.FORGOT.EMAIL_SENT' })
          : `${intl.formatMessage({ id: 'ERROR' })} ${recoveryPasswordErrors}`,
        { variant: recoveryPasswordSuccess ? 'success' : 'error' }
      );
    }
    if (recoveryPasswordSuccess) {
      history.push('/auth');
    }
    return () => {
      clearRecoveryPassword();
    };
  }, [
    recoveryPasswordSuccess,
    recoveryPasswordErrors,
    enqueueSnackbar,
    clearRecoveryPassword,
    history,
    intl,
  ]);

  return (
    <div className="kt-login__body">
      <div className={`kt-login__form ${classes.form}`}>
        <div className="kt-login__title" style={{ marginTop: '1.5rem', marginBottom: '3rem' }}>
          <h3 style={{ fontWeight: 700, fontSize: '2rem', color: '#043655' }}>
            <FormattedMessage id="AUTH.FORGOT.TITLE" />
          </h3>
        </div>

        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(intl.formatMessage({ id: 'AUTH.INPUT.EMAIL_INCORRECT' }))
              .required(intl.formatMessage({ id: 'AUTH.INPUT.EMAIL_REQUIRED' })),
          })}
          onSubmit={(values) => {
            recoveryPasswordRequest({ email: values.email });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="kt-form">
              <div role="alert" className="alert alert-info">
                <div className="alert-text">
                  {`${intl.formatMessage({
                    id: 'AUTH.FORGOT.INPUT_TEXT1',
                  })} e-mail ${intl.formatMessage({ id: 'AUTH.FORGOT.INPUT_TEXT2' })}`}
                </div>
              </div>

              <div className="form-group">
                <TextField
                  type="email"
                  label="Email"
                  margin="normal"
                  fullWidth={true}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                />
              </div>

              <div className="kt-login__actions">
                <Grid container direction="row" justify="space-between" alignItems="stretch">
                  <Button onClick={() => history.push('/auth')} variant="outlined" color="primary">
                    {intl.formatMessage({ id: 'COMMON.BUTTON.BACK' })}
                  </Button>

                  <ButtonWithLoader
                    disabled={recoveryPasswordLoading}
                    loading={recoveryPasswordLoading}
                  >
                    {intl.formatMessage({ id: 'AUTH.LOGIN.FORGOT_PASSWORD' })}
                  </ButtonWithLoader>
                </Grid>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const connector = connect(
  (state: IAppState) => ({
    recoveryPasswordLoading: state.auth.recoveryPasswordLoading,
    recoveryPasswordSuccess: state.auth.recoveryPasswordSuccess,
    recoveryPasswordErrors: state.auth.recoveryPasswordErrors,
  }),
  {
    clearRecoveryPassword: authActions.clearRecoveryPassword,
    recoveryPasswordRequest: authActions.recoveryPasswordRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(ForgotPassword));
