import axios from 'axios';
import { ICreateSubscription, ITariff, ITariffEditProps } from '../interfaces/tariff';
import {
  TARIFFS_URL,
  getTariffByIdUrl,
  createTariffPayment,
  createTariffSubscription,
} from '../constants';
import { UserRoles } from '../interfaces/user';

export const getTariffs = (role: UserRoles) => {
  return axios.get(`${TARIFFS_URL}?role=${role}`);
};

export const editTariff = (id: number, data: ITariffEditProps) =>
  axios.put(getTariffByIdUrl(id), data);

export const getTariffById = (id: number) => axios.get(getTariffByIdUrl(id));

export const createPayment = (amount: number, tariff_id: number) =>
  axios.get(createTariffPayment(amount, tariff_id));

export const createSubscription = (data: ICreateSubscription) =>
  axios.post(createTariffSubscription(data));

export const getStripeUser = (data: ICreateSubscription) =>
  axios.get(createTariffSubscription(data));
