import axios from 'axios';

export const getJobResponses = (page: number = 1, perPage: number = 20, id: string) => {
  const url = 'api/shop/orders';
  // const statuses = 'new';
  return axios.get(`${url}?page=${page}&per_page=${perPage}&product_id=${id}`);
};

export const getMessages = (page: number = 1, perPage: number = 20, id: number) => {
  return axios.get(`api/messages/list?order_id=${id}&page=${page}&per_page=${perPage}`);
};

export const postMessages = (data: FormData) => {
  return axios.post('api/message', data);
};

export const getMyResponses = (page: number = 1, perPage: number = 20, status: string) => {
  const url = 'api/account/my_orders';
  return axios.get(`${url}?page=${page}&per_page=${perPage}&user_statuses=${status}`);
};

export const getMyResponseForProject = (id: number) => {
  return axios.get(`/api/shop/product_order/${id}`);
};

export const changeOrderStatus = (id: number, status: string) => {
  return axios.put(`/api/vendor/order_status/${id}?status=${status}`);
};

export const changeMyResponseStatus = (id: number, status: string) => {
  return axios.put('/api/account/order/' + id, { buyerStatus: status });
};
