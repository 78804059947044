import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IAppState } from '../../store/rootDuck';
import { actions as attributesActions } from '../../store/ducks/attribute.duck';
import { actions as usersActions } from '../../store/ducks/users.duck';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Preloader from '../ui/Preloader/Preloader';

// import Header from './header/Header';
// import Footer from './footer/Footer';
// import StickyToolbar from '../../app/partials/layout/StickyToolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  label: {
    color: '#fff',
    marginBottom: theme.spacing(2),
  },
  checkboxLabel: {
    color: '#fff',
  },
  checkbox: {
    color: '#fff',
  },
  divider: {
    background: theme.palette.divider,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#fff',
    backgroundColor: 'transparent',
  },
  accordion: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  icon: {
    color: '#fff',
  },
}));

const UsersFilter: React.FC<TPropsFromRedux> = ({
  filter,
  setFilter,
  clearFilter,

  countries,
  fetchCountries,
  loadingCountries,
  clearCountries,

  educations,
  fetchEducations,
  loadingEducations,
  clearEducations,

  skills,
  fetchSkills,
  loadingSkills,
  clearSkills,

  languages,
  fetchLanguages,
  loadingLanguages,
  clearLanguages,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  useEffect(() => {
    fetchCountries();

    return () => {
      clearCountries();
    };
  }, [fetchCountries]);

  useEffect(() => {
    fetchEducations();

    return () => {
      clearEducations();
    };
  }, [fetchEducations]);

  useEffect(() => {
    fetchSkills();

    return () => {
      clearSkills();
    };
  }, [fetchSkills]);

  useEffect(() => {
    fetchLanguages();

    return () => {
      clearLanguages();
    };
  }, [fetchLanguages]);

  const handleChange = (
    event: any,
    type: 'ediscoveries' | 'education_degree' | 'languages' | 'country_qualification'
  ) => {
    let value = filter[type];

    if (event.target.checked) {
      value.push(event.target.name);
    } else {
      const index = value.indexOf(event.target.name);
      if (index > -1) {
        value.splice(index, 1);
      }
    }

    setFilter({ [type]: value });
  };

  if (loadingCountries || loadingEducations || loadingSkills || loadingLanguages)
    return (
      <div
        style={{
          width: '100%',
          paddingTop: 25,
          paddingBottom: 25,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Preloader />
      </div>
    );

  return (
    <Col>
      <Row>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Country of qualification</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                {countries.map((item: string) => (
                  <FormControlLabel
                    key={item}
                    className={classes.checkboxLabel}
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        checked={filter.country_qualification.includes(item) || false}
                        onChange={(e) => handleChange(e, 'country_qualification')}
                        name={item}
                      />
                    }
                    label={item}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>CEducation / Degree</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                {educations.map((item) => (
                  <FormControlLabel
                    key={item}
                    className={classes.checkboxLabel}
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        checked={filter.education_degree.includes(item) || false}
                        onChange={(e) => handleChange(e, 'education_degree')}
                        name={item}
                      />
                    }
                    label={item}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}> eDiscovery skills</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                {skills.map((item) => (
                  <FormControlLabel
                    key={item}
                    className={classes.checkboxLabel}
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        checked={filter.ediscoveries.includes(item) || false}
                        onChange={(e) => handleChange(e, 'ediscoveries')}
                        name={item}
                      />
                    }
                    label={item}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography className={classes.heading}>Languages</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                {languages.map((item) => (
                  <FormControlLabel
                    key={item}
                    className={classes.checkboxLabel}
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        checked={filter.languages.includes(item) || false}
                        onChange={(e) => handleChange(e, 'languages')}
                        name={item}
                      />
                    }
                    label={item}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </Row>

      <Row>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel className={classes.label} component="legend">
            {intl.formatMessage({ id: 'USER.EDIT.ACTIVE' })}
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={filter.available_for_work}
                  onChange={(e) => {
                    setFilter({
                      available_for_work: e.target.checked,
                    });
                  }}
                  name={'available_for_work'}
                />
              }
              label={'Only available'}
            />
          </FormGroup>
        </FormControl>
      </Row>
    </Col>
  );
};

const mapStateToProps = (state: IAppState) => ({
  filter: state.users.filter,

  countries: state.attributes.countries,
  loadingCountries: state.attributes.countriesLoading,
  errorCountries: state.attributes.countriesError,
  successCountries: state.attributes.countriesSuccess,

  educations: state.attributes.educations,
  loadingEducations: state.attributes.educationsLoading,
  errorEducations: state.attributes.educationsError,
  successEducations: state.attributes.educationsSuccess,

  skills: state.attributes.skills,
  loadingSkills: state.attributes.skillsLoading,
  errorSkills: state.attributes.skillsError,
  successSkills: state.attributes.skillsSuccess,

  languages: state.attributes.languages,
  loadingLanguages: state.attributes.languagesLoading,
  errorLanguages: state.attributes.languagesError,
  successLanguages: state.attributes.languagesSuccess,
});

const mapDispatchToProps = {
  setFilter: usersActions.setFilter,
  clearFilter: usersActions.clearFilter,

  fetchCountries: attributesActions.fetchCountriesRequest,
  clearCountries: attributesActions.clearCountriesFetch,

  fetchEducations: attributesActions.fetchEducationsRequest,
  clearEducations: attributesActions.clearEducationsFetch,

  fetchSkills: attributesActions.fetchSkillsRequest,
  clearSkills: attributesActions.clearSkillsFetch,

  fetchLanguages: attributesActions.fetchLanguagesRequest,
  clearLanguages: attributesActions.clearLanguagesFetch,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UsersFilter);
