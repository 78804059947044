import axios from 'axios';
import {
  CREATE_CATEGORY_URL,
  ROOT_CATEGORIES_URL,
  FULL_CATEGORIES_URL,
  getCategoriesWithChildrenUrl,
  getCategoriesWithParentUrl,
  getCategoryUrl,
  getCategoriesPositionsUrl,
} from '../constants';
import { CategoryType, ICategoryItem } from '../pages/home/categories/interfaces';
import { IPaginationProps } from '../interfaces/server';

export function getRootCategories(
  type: CategoryType,
  { page = 1, per_page = 20 }: IPaginationProps
) {
  return axios.get(`${ROOT_CATEGORIES_URL}/${type}?page=${page}&per_page=${per_page}`);
}

export function getFullCategories(type: CategoryType) {
  return axios.get(`${FULL_CATEGORIES_URL}/${type}`);
}

export function getCategoriesWithChildren({ page = 1, per_page = 20, id = 0 }) {
  return axios.get(`${getCategoriesWithChildrenUrl(id)}`);
}

export function getCategoriesWithParent({ id = 0 }) {
  return axios.get(`${getCategoriesWithParentUrl(id)}`);
}

export function getCategoryById(id: number) {
  return axios.get(`${getCategoryUrl(id)}`);
}

export function createCategory(data: ICategoryItem) {
  const type = data.type;
  
  delete data.type;
  
  return axios.post(`${CREATE_CATEGORY_URL}/${type}`, data);
}

export function editCategory(id: number, data: ICategoryItem) {
  return axios.put(`${getCategoryUrl(id)}`, data);
}

export function deleteCategory(id: number | undefined) {
  return axios.delete(`${getCategoryUrl(id)}`);
}

export function setCategoriesPositions(positions: string) {
  return axios.post(getCategoriesPositionsUrl(positions));
}
