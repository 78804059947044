import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { TextField, Button, Grid } from '@material-ui/core';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

import { ButtonWithLoader } from '../../components/ui/Buttons';

import { actions as authActions } from '../../store/ducks/auth.duck';
import { IAppState } from '../../store/rootDuck';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    background: '#fff',
    padding: '20px 40px',
    borderRadius: '5px',
  },
}));

const NewPassword: React.FC<RouteComponentProps<{ code: string }> & TPropsFromRedux> = ({
  match: {
    params: { code },
  },
  clearNewPassword,
  newPasswordRequest,
  newPasswordLoading,
  newPasswordSuccess,
  newPasswordErrors,
}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const classes = useStyles();

  useEffect(() => {
    if (newPasswordSuccess || newPasswordErrors) {
      enqueueSnackbar(
        newPasswordSuccess
          ? intl.formatMessage({ id: 'AUTH.PASSWORD.SUCCESS' })
          : `${intl.formatMessage({ id: 'ERROR' })} ${newPasswordErrors}`,
        { variant: newPasswordSuccess ? 'success' : 'error' }
      );
    }
    if (newPasswordSuccess) {
      history.push('/auth');
    }
    return () => {
      clearNewPassword();
    };
  }, [newPasswordSuccess, newPasswordErrors, enqueueSnackbar, clearNewPassword, history, intl]);

  return (
    <div className="kt-login__body">
      <div className={`kt-login__form ${classes.form}`}>
        <div className="kt-login__title" style={{ marginTop: '1.5rem', marginBottom: '3rem' }}>
          <h3 style={{ fontWeight: 700, fontSize: '2.5rem', color: '#043655' }}>New password</h3>
        </div>

        <Formik
          initialValues={{ password: '', password2: '' }}
          validationSchema={Yup.object().shape({
            password: Yup.string().required(intl.formatMessage({ id: 'AUTH.PASSWORD.INPUT' })),
            password2: Yup.string()
              .required(intl.formatMessage({ id: 'AUTH.PASSWORD.REPEAT' }))
              .oneOf([Yup.ref('password'), ''], intl.formatMessage({ id: 'AUTH.PASSWORD.EQUAL' })),
          })}
          onSubmit={(values) => {
            newPasswordRequest({ password: values.password, password2: values.password2, code });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="kt-form">
              <div role="alert" className="alert alert-info" style={{ margin: '0 0 30px 0' }}>
                <div className="alert-text">
                  Enter and confirm a new <strong> password </strong>
                </div>
              </div>

              <h5 style={{ fontWeight: 600 }}>Password Requirements</h5>

              <div style={{ fontWeight: 300, color: 'black' }}>
                <ul style={{ paddingInlineStart: 17 }}>
                  <li>At least 8 characters</li>
                  <li>
                    All 4 of the following: uppercase, lowercase, numeric and special characters
                  </li>
                  <li>
                    {
                      'The allowed special characters are ~ ! @ # $ % ^ * - _ = + [ { ] } / ; : , . ?  [no spaces allowed!]'
                    }
                  </li>
                </ul>
              </div>

              <div className="form-group">
                <TextField
                  autoComplete="off"
                  type="password"
                  margin="normal"
                  label="New password"
                  className="kt-width-full"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
              </div>

              <div className="form-group">
                <TextField
                  autoComplete="off"
                  type="password"
                  margin="normal"
                  label="New password again"
                  className="kt-width-full"
                  name="password2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password2}
                  helperText={touched.password2 && errors.password2}
                  error={Boolean(touched.password2 && errors.password2)}
                />
              </div>

              <div className="kt-login__actions">
                <Grid container direction="row" justify="space-between" alignItems="stretch">
                  <Button onClick={() => history.push('/auth')} variant="outlined" color="primary">
                    {intl.formatMessage({ id: 'COMMON.BUTTON.BACK' })}
                  </Button>

                  <ButtonWithLoader disabled={newPasswordLoading} loading={newPasswordLoading}>
                    {intl.formatMessage({ id: 'AUTH.PASSWORD.BUTTON' })}
                  </ButtonWithLoader>
                </Grid>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const connector = connect(
  (state: IAppState) => ({
    newPasswordLoading: state.auth.newPasswordLoading,
    newPasswordSuccess: state.auth.newPasswordSuccess,
    newPasswordErrors: state.auth.newPasswordErrors,
  }),
  {
    clearNewPassword: authActions.clearNewPassword,
    newPasswordRequest: authActions.newPasswordRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NewPassword);
