import axios from 'axios';
import { STORES_URL, CREATE_STORE_URL, getStoreByIdUrl } from '../constants';
import { IStore } from '../interfaces/store';

export const createStore = (data: IStore) => {
  return axios.post(CREATE_STORE_URL, data);
};

export const getStores = (page: number, perPage: number, companyId?: number) => {
  return axios.get(
    `${STORES_URL}${companyId ? `?company_id=${companyId}` : ''}?page=${page}&perPage=${perPage}`
  );
};

export const editStore = (id: number, data: IStore) => axios.put(getStoreByIdUrl(id), data);

export const deleteStore = (id: number) => axios.delete(getStoreByIdUrl(id));

export const getStoreById = (id: number) => axios.get(getStoreByIdUrl(id));
