import React from 'react';

const betaText = (
  <div className="text-center mt-5" style={{ color: '#fff', fontSize: '1.1rem', maxWidth: 300 }}>
    We launched in <span style={{ color: '#EF8B30' }}>beta</span> mode. <br /> Please send your
    feedback to{' '}
    <a style={{ color: '#EF8B30' }} href="mailto:info@reviewroom.app">
      info@reviewroom.app
    </a>
  </div>
);

export default betaText;
