export const toFormData: (datas: any) => FormData = datas => {
  let formData = new FormData();
  for (var key in datas) {
    if (Array.isArray(datas[key])) {
      for (var i = 0; i < datas[key].length; i++) {
        formData.append(`${key}[]`, datas[key][i]);
      }
    } else {
      formData.append(key, datas[key]);
    }
  }
  return formData;
};

export const getResponseMessage = (e: any) =>
  (e && e.response && e.response.data && e.response.data.message) || 'Error';

export const numberWithSpaces = (x: number) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return x;
};
