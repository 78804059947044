import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { actions as authActions } from '../../store/ducks/auth.duck';
import ButtonWithLoader from '../../components/ui/Buttons/ButtonWithLoader';
import { IAppState } from '../../store/rootDuck';
import { TRole } from './interfaces';
import TermDialog from '../../components/ui/Dialog/TermDialog';
import { makeStyles } from '@material-ui/core/styles';

import FreelancerRadio from '../../assets/icons/radio-freelancer.svg';
import BusinessRadio from '../../assets/icons/radio-business.svg';
import Topbar from '../../../_metronic/layout/header/LanguageTopbar';

const useStyles = makeStyles((theme) => ({
  form: {
    background: '#fff',
    padding: '20px 40px',
    borderRadius: '5px',
  },
  radioContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  radioImg: {
    width: 25,
    height: 25,
    marginRight: theme.spacing(1),
  },
  headerBtn: {
    background: '#fff',
    marginLeft: theme.spacing(1),

    '&:hover': {
      background: '#c0c0c0',
    },
  },
}));

const Registration: React.FC<TPropsFromRedux & WrappedComponentProps> = ({
  intl,
  clearReg,
  register,
  regLoading,
  regSuccess,
  regError,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContentType, setDialogContentType] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (regError) {
      enqueueSnackbar(regError, {
        variant: 'error',
      });
      clearReg();
    }
    if (regSuccess) {
      history.push('/auth/email-sent/registration');
      clearReg();
    }
  }, [clearReg, enqueueSnackbar, history, regError, regSuccess]);

  const handleDialogOpen = useCallback(
    (contentType) => {
      setDialogContentType(contentType);
      setOpenDialog(true);
    },
    [setDialogContentType, setOpenDialog]
  );

  return (
    <>
      <TermDialog
        isOpen={openDialog}
        handleClose={() => setOpenDialog(false)}
        contentType={dialogContentType}
      />
      <div className="kt-login__head">
        <div className="kt-login__signup">
          <span className="kt-login__signup-label" style={{ color: '#fff' }}>
            {intl.formatMessage({ id: 'AUTH.GENERAL.HAVE_ACCOUNT' })}
          </span>
          &nbsp;&nbsp;
          <Button
            onClick={() => {
              history.push('/auth');
            }}
            className={classes.headerBtn}
            variant="text"
            color="primary"
          >
            {intl.formatMessage({ id: 'AUTH.GENERAL.SIGNIN_BUTTON' })}
          </Button>
        </div>
      </div>

      <div className="kt-login__body">
        <div className={`kt-login__form ${classes.form}`}>
          {/*<div className="kt-login__title">*/}
          {/*  <img alt="Logo" src={toAbsoluteUrl('/media/logos/logo-title.png')} />*/}
          {/*</div>*/}
          <div className="kt-login__title" style={{ marginTop: '1.5rem', marginBottom: '3rem' }}>
            <h3 style={{ fontWeight: 700, fontSize: '2.5rem', color: '#043655' }}>
              <FormattedMessage id="AUTH.SIGNUP.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              email: '',
              login: '',
              role: 'ROLE_BUYER',
              acceptTerms: false,
              notifications: true,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email(intl.formatMessage({ id: 'AUTH.VALIDATION.INVALID_FIELD' }))
                .required(intl.formatMessage({ id: 'PROFILE.VALIDATION.REQUIRED_FIELD' })),
            })}
            onSubmit={(values) => {
              register({
                data: {
                  email: values.email,
                  roles: [values.role as TRole],
                  login: values.email,
                },
                status: values.notifications,
              });
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <RadioGroup
                  name="role"
                  value={values.role}
                  onChange={handleChange}
                  style={{ marginTop: '3rem' }}
                >
                  <FormLabel
                    component="legend"
                    style={{ fontWeight: 700, fontSize: '1.1rem', marginBottom: 30 }}
                  >
                    {intl.formatMessage({ id: 'AUTH.REGISTER.ROLE_TITLE' })}
                  </FormLabel>

                  <div className={classes.radioContainer}>
                    <img className={classes.radioImg} src={FreelancerRadio} />

                    <FormControlLabel
                      style={{ marginBottom: 0 }}
                      value="ROLE_BUYER"
                      control={<Radio />}
                      label={intl.formatMessage({ id: 'AUTH.REGISTER.BUYER' })}
                    />
                  </div>

                  <div className={classes.radioContainer}>
                    <img className={classes.radioImg} src={BusinessRadio} />

                    <FormControlLabel
                      style={{ marginBottom: 0 }}
                      value="ROLE_VENDOR"
                      control={<Radio color="primary" />}
                      label={intl.formatMessage({ id: 'AUTH.REGISTER.VENDOR' })}
                    />
                  </div>
                </RadioGroup>

                <div className="form-group mb-0">
                  <TextField
                    label={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div
                  className="form-group mb-0"
                  style={{ fontWeight: 400, alignItems: 'flex-start', display: 'flex' }}
                >
                  <Checkbox
                    color="primary"
                    name="acceptTerms"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    checked={values.acceptTerms}
                    style={{ paddingLeft: 0, fontSize: 10 }}
                  />
                  <div style={{ paddingTop: 9 }}>
                    <span style={{ fontSize: 10, color: '#adadad' }}>
                      {intl.formatMessage({ id: 'AUTH.REGISTER.AGREE_TERM' })}{' '}
                    </span>

                    <span
                      className="kt-link"
                      style={{ cursor: 'pointer', fontWeight: 700, color: '#ef8b30', fontSize: 10 }}
                      onClick={() => handleDialogOpen('user_agreement')}
                    >
                      {intl.formatMessage({ id: 'AUTH.GENERAL.LEGAL' })}
                    </span>
                    <span style={{ marginRight: 4, marginLeft: 4, fontSize: 10 }}>&</span>
                    <span
                      className="kt-link"
                      style={{ cursor: 'pointer', fontWeight: 700, color: '#ef8b30', fontSize: 10 }}
                      onClick={() => handleDialogOpen('privacy_policy')}
                    >
                      {`${intl.formatMessage({ id: 'SUBMENU.LEGAL_PRIVACY' })}.`}
                    </span>
                  </div>
                </div>

                <div
                  className="form-group mb-0"
                  style={{ fontWeight: 400, alignItems: 'flex-start', display: 'flex' }}
                >
                  <Checkbox
                    color="primary"
                    name="notifications"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    checked={values.notifications}
                    style={{ paddingLeft: 0, fontSize: 10 }}
                  />
                  <div style={{ paddingTop: 9 }}>
                    <span style={{ fontSize: 10, color: '#adadad' }}>
                      {intl.formatMessage({ id: 'AUTH.REGISTER.NOTIFICATION' })}{' '}
                    </span>
                  </div>
                </div>

                <div className="kt-login__actions">
                  <Link to="/auth">
                    <button
                      type="button"
                      className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                    >
                      {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}
                    </button>
                  </Link>

                  <ButtonWithLoader
                    disabled={regLoading || !values.acceptTerms}
                    onPress={handleSubmit}
                    loading={regLoading}
                  >
                    {'get started free'}
                  </ButtonWithLoader>
                </div>
              </form>
            )}
          </Formik>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              alignSelf: 'flex-end',
              marginBottom: '1.5rem',
              textAlign: 'center',
            }}
          >
            <p>
              Already have an account?{' '}
              <Link className="kt-link" to="/auth">
                Login
              </Link>
            </p>

            <Link className="kt-link" to="/auth/forgot-password">
              {intl.formatMessage({ id: 'AUTH.LOGIN.FORGOT_PASSWORD' })}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    regLoading: state.auth.regLoading,
    regSuccess: state.auth.regSuccess,
    regError: state.auth.regError,
  }),
  {
    register: authActions.regRequest,
    clearReg: authActions.clearReg,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(injectIntl(Registration));
