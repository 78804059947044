import axios from 'axios';
import { ME_URL, checkoutUrl, deleteUrl } from '../constants';
import { IUserEditProps } from '../interfaces/user';

export function getMe() {
  return axios.get(ME_URL);
}

export function editMe(data: IUserEditProps) {
  let url = ME_URL;

  if (data.countries_of_qualification) {
    url += `${url === ME_URL ? '?' : '&'}countries=${data.countries_of_qualification.join(',')}`;
  }

  if (data.education_degree) {
    url += `${url === ME_URL ? '?' : '&'}degrees=${data.education_degree.join(',')}`;
  }

  if (data.e_discovery_skills) {
    url += `${url === ME_URL ? '?' : '&'}ediscovery=${data.e_discovery_skills.join(',')}`;
  }

  if (data.languages) {
    url += `${url === ME_URL ? '?' : '&'}languages=${data.languages.join(',')}`;
  }

  if (data.category_id) {
    url += `${url === ME_URL ? '?' : '&'}category_id=${data.category_id}`;
  }

  return axios.put(url, data);
}

export function uploadAvatar(data: FormData) {
  return axios.post(ME_URL + '/upload_photos', data);
}

export function uploadResume(data: FormData) {
  return axios.post(ME_URL + '/upload_resume', data);
}

export function deleteResume() {
  return axios.delete(ME_URL + '/delete_resume');
}

export function checkout(id: number, data: { buyerStatus: string; comment: string }) {
  return axios.post(checkoutUrl(id), data, {
    headers: { 'content-type': 'application/json' },
  });
}

export function deleteMe(id: number) {
  return axios.delete(deleteUrl(id));
}

export const addNotifications = (data: string) => {
  return axios.post(`api/notifications/add?types=${data}`);
};

export const removeNotifications = (data: string) => {
  return axios.delete(`api/notifications/remove?types=${data}`);
};
