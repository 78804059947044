import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  CountriesType,
  EducationsType,
  LanguagesType,
  SkillsType,
} from '../../interfaces/attributes';
import { getCountries, getEducations, getLanguages, getSkills } from '../../crud/attribute.crud';

const CLEAR_FETCH_COUNTRIES = 'attributes/CLEAR_FETCH_COUNTRIES';
const FETCH_REQUEST_COUNTRIES = 'attributes/FETCH_REQUEST_COUNTRIES';
const FETCH_SUCCESS_COUNTRIES = 'attributes/FETCH_SUCCESS_COUNTRIES';
const FETCH_FAIL_COUNTRIES = 'attributes/FETCH_FAIL_COUNTRIES';

const CLEAR_FETCH_EDUCATIONS = 'attributes/CLEAR_FETCH_EDUCATIONS';
const FETCH_REQUEST_EDUCATIONS = 'attributes/FETCH_REQUEST_EDUCATIONS';
const FETCH_SUCCESS_EDUCATIONS = 'attributes/FETCH_SUCCESS_EDUCATIONS';
const FETCH_FAIL_EDUCATIONS = 'attributes/FETCH_FAIL_EDUCATIONS';

const CLEAR_FETCH_SKILLS = 'attributes/CLEAR_FETCH_SKILLS';
const FETCH_REQUEST_SKILLS = 'attributes/FETCH_REQUEST_SKILLS';
const FETCH_SUCCESS_SKILLS = 'attributes/FETCH_SUCCESS_SKILLS';
const FETCH_FAIL_SKILLS = 'attributes/FETCH_FAIL_SKILLS';

const CLEAR_FETCH_LANGUAGES = 'attributes/CLEAR_FETCH_LANGUAGES';
const FETCH_REQUEST_LANGUAGES = 'attributes/FETCH_REQUEST_LANGUAGES';
const FETCH_SUCCESS_LANGUAGES = 'attributes/FETCH_SUCCESS_LANGUAGES';
const FETCH_FAIL_LANGUAGES = 'attributes/FETCH_FAIL_LANGUAGES';

export interface IInitialState {
  countries: CountriesType[];
  countriesLoading: boolean;
  countriesSuccess: boolean;
  countriesError: string | null;

  educations: EducationsType[];
  educationsLoading: boolean;
  educationsSuccess: boolean;
  educationsError: string | null;

  skills: SkillsType[];
  skillsLoading: boolean;
  skillsSuccess: boolean;
  skillsError: string | null;

  languages: LanguagesType[];
  languagesLoading: boolean;
  languagesSuccess: boolean;
  languagesError: string | null;
}

const initialState: IInitialState = {
  countries: [],
  countriesLoading: false,
  countriesSuccess: false,
  countriesError: null,

  educations: [],
  educationsLoading: false,
  educationsSuccess: false,
  educationsError: null,

  skills: [],
  skillsLoading: false,
  skillsSuccess: false,
  skillsError: null,

  languages: [],
  languagesLoading: false,
  languagesSuccess: false,
  languagesError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'attributes', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_FETCH_COUNTRIES: {
        return {
          ...state,
          countries: [],
          countriesLoading: false,
          countriesError: null,
          countriesSuccess: false,
        };
      }

      case FETCH_REQUEST_COUNTRIES: {
        return {
          ...state,
          countries: [],
          countriesLoading: true,
          countriesSuccess: false,
          countriesError: null,
        };
      }

      case FETCH_SUCCESS_COUNTRIES: {
        return {
          ...state,
          countries: action.payload.data,
          countriesLoading: false,
          countriesSuccess: true,
        };
      }

      case FETCH_FAIL_COUNTRIES: {
        return { ...state, countriesLoading: false, countriesError: action.payload };
      }

      case CLEAR_FETCH_EDUCATIONS: {
        return {
          ...state,
          educations: [],
          educationsLoading: false,
          educationsError: null,
          educationsSuccess: false,
        };
      }

      case FETCH_REQUEST_EDUCATIONS: {
        return {
          ...state,
          educations: [],
          educationsLoading: true,
          educationsSuccess: false,
          educationsError: null,
        };
      }

      case FETCH_SUCCESS_EDUCATIONS: {
        return {
          ...state,
          educations: action.payload.data,
          educationsLoading: false,
          educationsSuccess: true,
        };
      }

      case FETCH_FAIL_EDUCATIONS: {
        return { ...state, educationsLoading: false, educationsError: action.payload };
      }

      case CLEAR_FETCH_SKILLS: {
        return {
          ...state,
          skills: [],
          skillsLoading: false,
          skillsError: null,
          skillsSuccess: false,
        };
      }

      case FETCH_REQUEST_SKILLS: {
        return {
          ...state,
          skills: [],
          skillsLoading: true,
          skillsSuccess: false,
          skillsError: null,
        };
      }

      case FETCH_SUCCESS_SKILLS: {
        return {
          ...state,
          skills: action.payload.data,
          skillsLoading: false,
          skillsSuccess: true,
        };
      }

      case FETCH_FAIL_SKILLS: {
        return { ...state, skillsLoading: false, skillsError: action.payload };
      }

      case CLEAR_FETCH_LANGUAGES: {
        return {
          ...state,
          languages: [],
          languagesLoading: false,
          languagesError: null,
          languagesSuccess: false,
        };
      }

      case FETCH_REQUEST_LANGUAGES: {
        return {
          ...state,
          languages: [],
          languagesLoading: true,
          languagesSuccess: false,
          languagesError: null,
        };
      }

      case FETCH_SUCCESS_LANGUAGES: {
        return {
          ...state,
          languages: action.payload.data,
          languagesLoading: false,
          languagesSuccess: true,
        };
      }

      case FETCH_FAIL_LANGUAGES: {
        return { ...state, languagesLoading: false, languagesError: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearCountriesFetch: () => createAction(CLEAR_FETCH_COUNTRIES),
  fetchCountriesRequest: () => createAction(FETCH_REQUEST_COUNTRIES),
  fetchCountriesSuccess: (payload: IServerResponse<CountriesType[]>) =>
    createAction(FETCH_SUCCESS_COUNTRIES, payload),
  fetchCountriesFail: (payload: string) => createAction(FETCH_FAIL_COUNTRIES, payload),

  clearEducationsFetch: () => createAction(CLEAR_FETCH_EDUCATIONS),
  fetchEducationsRequest: () => createAction(FETCH_REQUEST_EDUCATIONS),
  fetchEducationsSuccess: (payload: IServerResponse<EducationsType[]>) =>
    createAction(FETCH_SUCCESS_EDUCATIONS, payload),
  fetchEducationsFail: (payload: string) => createAction(FETCH_FAIL_EDUCATIONS, payload),

  clearSkillsFetch: () => createAction(CLEAR_FETCH_SKILLS),
  fetchSkillsRequest: () => createAction(FETCH_REQUEST_SKILLS),
  fetchSkillsSuccess: (payload: IServerResponse<SkillsType[]>) =>
    createAction(FETCH_SUCCESS_SKILLS, payload),
  fetchSkillsFail: (payload: string) => createAction(FETCH_FAIL_SKILLS, payload),

  clearLanguagesFetch: () => createAction(CLEAR_FETCH_LANGUAGES),
  fetchLanguagesRequest: () => createAction(FETCH_REQUEST_LANGUAGES),
  fetchLanguagesSuccess: (payload: IServerResponse<LanguagesType[]>) =>
    createAction(FETCH_SUCCESS_LANGUAGES, payload),
  fetchLanguagesFail: (payload: string) => createAction(FETCH_FAIL_LANGUAGES, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchCountries() {
  try {
    const { data }: { data: IServerResponse<CountriesType[]> } = yield call(() => getCountries());
    yield put(actions.fetchCountriesSuccess(data));
  } catch (e) {
    yield put(actions.fetchCountriesFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchEducations() {
  try {
    const { data }: { data: IServerResponse<EducationsType[]> } = yield call(() => getEducations());
    yield put(actions.fetchEducationsSuccess(data));
  } catch (e) {
    yield put(actions.fetchEducationsFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchSkills() {
  try {
    const { data }: { data: IServerResponse<SkillsType[]> } = yield call(() => getSkills());
    yield put(actions.fetchSkillsSuccess(data));
  } catch (e) {
    yield put(actions.fetchSkillsFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchLanguages() {
  try {
    const { data }: { data: IServerResponse<LanguagesType[]> } = yield call(() => getLanguages());
    yield put(actions.fetchLanguagesSuccess(data));
  } catch (e) {
    yield put(actions.fetchLanguagesFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchCountriesRequest>>(
    FETCH_REQUEST_COUNTRIES,
    fetchCountries
  );
  yield takeLatest<ReturnType<typeof actions.fetchCountriesRequest>>(
    FETCH_REQUEST_EDUCATIONS,
    fetchEducations
  );
  yield takeLatest<ReturnType<typeof actions.fetchCountriesRequest>>(
    FETCH_REQUEST_SKILLS,
    fetchSkills
  );
  yield takeLatest<ReturnType<typeof actions.fetchCountriesRequest>>(
    FETCH_REQUEST_LANGUAGES,
    fetchLanguages
  );
}
