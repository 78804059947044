import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IAppState } from '../../store/rootDuck';
import { actions as usersActions } from '../../store/ducks/users.duck';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { actions as categoriesActions } from '../../store/ducks/categories.duck';
import Preloader from '../ui/Preloader/Preloader';

// import Header from './header/Header';
// import Footer from './footer/Footer';
// import StickyToolbar from '../../app/partials/layout/StickyToolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    marginLeft: theme.spacing(2),
    color: '#fff',
  },
  label: {
    color: '#fff',
    marginBottom: theme.spacing(2),
  },
  checkboxLabel: {
    color: '#fff',
  },
  checkbox: {
    color: '#fff',
  },
  divider: {
    background: theme.palette.divider,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#fff',
    backgroundColor: 'transparent',
  },
  accordion: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  icon: {
    color: '#fff',
  },
}));

const UsersCatalogFilter: React.FC<TPropsFromRedux> = ({
  filter,
  setFilter,
  clearFilter,

  categories,
  fetchCategories,
  categoriesLoading,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  useEffect(() => {
    return () => {
      clearFilter();
    };
  }, []);

  useEffect(() => {
    fetchCategories({ type: 'users' });
  }, [fetchCategories]);

  let data = (categories || []).map((cat) => ({
    value: cat.id?.toString(),
    label: cat.name,
  }));

  data = [
    {
      value: 'all',
      label: 'All',
    },
    ...data,
  ];

  if (categoriesLoading)
    return (
      <div
        style={{
          width: '100%',
          paddingTop: 25,
          paddingBottom: 25,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Preloader />
      </div>
    );

  return (
    <Col>
      <Row>
        <RadioGroup name={'category_id'} value={filter.category_id}>
          {data.map((item, index) => {
            return (
              <Row key={index.toString()}>
                <FormControlLabel
                  className={classes.formControl}
                  control={
                    <Radio
                      className={classes.checkbox}
                      onClick={() => setFilter({ category_id: item.value })}
                    />
                  }
                  value={item.value}
                  label={item.label}
                />
              </Row>
            );
          })}
        </RadioGroup>
      </Row>
    </Col>
  );
};

const mapStateToProps = (state: IAppState) => ({
  filter: state.users.filter,

  categories: state.categories.categories,
  categoriesLoading: state.categories.loading,
});

const mapDispatchToProps = {
  setFilter: usersActions.setFilter,
  clearFilter: usersActions.clearFilter,

  fetchCategories: categoriesActions.fetchFullRequest,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UsersCatalogFilter);
