import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { LayoutSplashScreen } from '../../../_metronic';
import getMenuConfig from '../../router/MenuConfig';
import * as builder from '../../../_metronic/ducks/builder';
import { IAppState } from '../../store/rootDuck';
import { UserRoles } from '../../interfaces/user';

const CategoriesPage = lazy(() => import('./categories'));
const UsersPage = lazy(() => import('./users'));
const CompaniesPage = lazy(() => import('./companies'));
const UserDocPage = lazy(() => import('./userDocs'));
const SettingsPage = lazy(() => import('./settings'));
const ProductsPage = lazy(() => import('./products'));
const OrdersPage = lazy(() => import('./orders'));
const StorePage = lazy(() => import('./store'));
const TariffsPage = lazy(() => import('./tariffs'));
const BlogPage = lazy(() => import('./blog'));
const NotificationsPage = lazy(() => import('./settings/emailNotifications'));

const HomePage: React.FC<TPropsFromRedux & { userLastLocation?: any }> = ({
  isAuthorized,
  userRoles,
  setMenuConfig,
}) => {
  const [menuConfig] = useState(getMenuConfig(userRoles, isAuthorized));

  useEffect(() => {
    setMenuConfig(menuConfig);
  }, [setMenuConfig, menuConfig]);

  const isAdmin = userRoles?.includes(UserRoles.ROLE_ADMIN);
  const isVendor = userRoles?.includes(UserRoles.ROLE_VENDOR);
  const isBuyer = userRoles?.includes(UserRoles.ROLE_BUYER);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {
        /* Redirect from root URL to /catalog. */
        isAuthorized ? (
          <>
            {isAdmin && <Redirect exact from="/" to="/products/list" />}
            {isVendor && <Redirect exact from="/" to="/companies/list" />}
            {isBuyer && <Redirect exact from="/" to="/settings/profile" />}
          </>
        ) : (
          <Redirect exact from="/" to="/blog/newsfeed" />
        )
      }
      <Route path="/settings" component={SettingsPage} />
      <Route path="/categories" component={CategoriesPage} />
      <Route path="/products" component={ProductsPage} />
      <Route path="/documents" component={UserDocPage} />
      <Route path="/users" component={UsersPage} />
      <Route path="/companies" component={CompaniesPage} />
      <Route path="/orders" component={OrdersPage} />
      <Route path="/stores" component={StorePage} />
      <Route path="/tariffs" component={TariffsPage} />
      <Route path="/blog" component={BlogPage} />
      <Route path="/notifications" component={NotificationsPage} />
    </Suspense>
  );
};

const connector = connect(
  (state: IAppState) => ({
    userRoles: state.auth.user?.roles,
    isAuthorized: state.auth.user != null,
  }),
  {
    setMenuConfig: builder.actions.setMenuConfig,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HomePage);
