import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { put, takeLatest, call } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IMyResponse, IJobResponse } from '../../interfaces/jobResponse';

import {
  getMyResponses,
  changeMyResponseStatus,
  getMyResponseForProject,
} from '../../crud/jobResponses.crud';

const FETCH_REQUEST = 'myResponses/FETCH_REQUEST';
const FETCH_SUCCESS = 'myResponses/FETCH_SUCCESS';
const FETCH_FAIL = 'myResponses/FETCH_FAIL';

const CHANGE_STATUS_REQUEST = 'myResponses/CHANGE_STATUS_REQUEST';
const CHANGE_STATUS_FAIL = 'myResponses/CHANGE_STATUS_FAIL';
const CLEAR_CHANGE_STATUS = 'myResponses/CLEAR_CHANGE_STATUS';

const FETCH_MY_RES_FOR_PROJECT_REQUEST = 'myResponses/FETCH_MY_RES_FOR_PROJECT_REQUEST';
const FETCH_MY_RES_FOR_PROJECT_SUCCESS = 'myResponses/FETCH_MY_RES_FOR_PROJECT_SUCCESS';
const FETCH_MY_RES_FOR_PROJECT_FAIL = 'myResponses/FETCH_MY_RES_FOR_PROJECT_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  myResponses: IMyResponse[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  changeStatusError: string | null;

  myResForProject: { comment: string; created_at: Date; id: number } | undefined;
  myResForProjectLoading: boolean;
  myResForProjectSuccess: boolean;
  myResForProjectError: string | null;
}

const defaultPaginatorProps = {
  page: 1,
  per_page: 20,
  total: 0,
};

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  myResponses: undefined,
  loading: false,
  success: false,
  error: null,

  changeStatusError: null,

  myResForProject: undefined,
  myResForProjectLoading: false,
  myResForProjectSuccess: false,
  myResForProjectError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'myResponses', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REQUEST: {
        return {
          ...state,
          myResponses: undefined,
          loading: true,
          success: false,
          error: null,
          page: action.payload.page,
          per_page: action.payload.perPage,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          total: action.payload.total,
          myResponses: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case CHANGE_STATUS_FAIL: {
        return { ...state, changeStatusError: action.payload };
      }

      case CLEAR_CHANGE_STATUS: {
        return { ...state, changeStatusError: null };
      }

      case FETCH_MY_RES_FOR_PROJECT_REQUEST: {
        return {
          ...state,
          myResForProject: undefined,
          myResForProjectLoading: true,
          myResForProjectSuccess: false,
          myResForProjectError: null,
        };
      }

      case FETCH_MY_RES_FOR_PROJECT_SUCCESS: {
        return {
          ...state,
          myResForProject: action.payload.data,
          myResForProjectLoading: false,
          myResForProjectSuccess: true,
        };
      }

      case FETCH_MY_RES_FOR_PROJECT_FAIL: {
        return {
          ...state,
          myResForProjectLoading: false,
          myResForProjectSuccess: false,
          myResForProjectError: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: { page: number; perPage: number; statuses: string }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IMyResponse[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  changeStatusRequest: (payload: { id: number; page: number; perPage: number; status: string }) =>
    createAction(CHANGE_STATUS_REQUEST, payload),
  changeStatusFail: (payload: string) => createAction(CHANGE_STATUS_FAIL, payload),
  clearChangeStatus: () => createAction(CLEAR_CHANGE_STATUS),

  fetchMyResForProject: (payload: { id: number }) =>
    createAction(FETCH_MY_RES_FOR_PROJECT_REQUEST, payload),
  fetchMyResForProjectSuccess: (
    payload: IServerResponse<{ comment: string; created_at: Date; id: number }>
  ) => createAction(FETCH_MY_RES_FOR_PROJECT_SUCCESS, payload),
  fetchMyResForProjectFail: (payload: string) =>
    createAction(FETCH_MY_RES_FOR_PROJECT_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({ payload }: { payload: { page: number; perPage: number; statuses: string } }) {
  try {
    const { data }: { data: IServerResponse<IMyResponse[]> } = yield call(() =>
      getMyResponses(payload.page, payload.perPage, payload.statuses)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* changeStatus({
  payload,
}: {
  payload: { id: number; page: number; perPage: number; status: string };
}) {
  try {
    yield call(() => changeMyResponseStatus(payload.id, payload.status));
    yield put(
      actions.fetchRequest({
        page: payload.page,
        perPage: payload.perPage,
        statuses: payload.status === 'new' ? 'canceled' : 'new',
      })
    );
  } catch (e) {
    yield put(actions.changeStatusFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchMyResForProject({ payload }: { payload: { id: number } }) {
  try {
    const {
      data,
    }: {
      data: IServerResponse<{ comment: string; created_at: Date; id: number }>;
    } = yield call(() => getMyResponseForProject(payload.id));
    yield put(actions.fetchMyResForProjectSuccess(data));
  } catch (e) {
    yield put(actions.fetchMyResForProjectFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.changeStatusRequest>>(
    CHANGE_STATUS_REQUEST,
    changeStatus
  );
  yield takeLatest<ReturnType<typeof actions.fetchMyResForProject>>(
    FETCH_MY_RES_FOR_PROJECT_REQUEST,
    fetchMyResForProject
  );
}
