/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, {useEffect} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {shallowEqual, useSelector} from 'react-redux';
import {useLastLocation} from 'react-router-last-location';
import {createStyles, makeStyles} from '@material-ui/core';
import {SnackbarProvider} from 'notistack';

import HomePage from '../pages/home/HomePage';
import ErrorsPage from '../pages/errors/ErrorsPage';
import LogoutPage from '../pages/auth/Logout';
import AuthPage from '../pages/auth/AuthPage';

import {LayoutContextProvider} from '../../_metronic';
import Layout from '../../_metronic/layout/Layout';

import * as routerHelpers from './RouterHelpers';

import {IAppState} from '../store/rootDuck';

const useStyles = makeStyles((theme) =>
  createStyles({
    success: {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
    error: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
  })
);

export const Routes = withRouter(({ history }) => {
  const classes = useStyles();
  const lastLocation = useLastLocation();

  routerHelpers.saveLastLocation(lastLocation);

  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, builder: { menuConfig } }: IAppState) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!isAuthorized) {
      routerHelpers.forgotLastLocation();
    }
  }, [isAuthorized]);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
      }}
    >
      {/* Create `LayoutContext` from current `history` and `menuConfig`. */}
      <LayoutContextProvider history={history} menuConfig={menuConfig}>
        <Switch>
          <Route path="/auth">
            <AuthPage />
          </Route>

          <Route path="/error" component={ErrorsPage} />
          <Route path="/logout" component={LogoutPage} />

          <Route path="/">
            <Layout>
              <HomePage userLastLocation={userLastLocation} />
            </Layout>
          </Route>
        </Switch>
      </LayoutContextProvider>
    </SnackbarProvider>
  );
});
