import axios from 'axios';
import {
  PRODUCT_URL,
  PRODUCTS_URL,
  getProductByIdUrl,
  getProductsByCategory,
  delPhotoFromProduct,
  setProductMainPhotoUrl,
} from '../constants';

export const createProduct = (files: FormData) => {
  return axios.post(PRODUCT_URL, files, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const editProduct = (id: number, files: FormData) => {
  return axios.post(getProductByIdUrl(id), files, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const deleteProduct = (id: number | undefined) => {
  return axios.delete(getProductByIdUrl(id || -1));
};

export const getProductById = (id: number) => {
  return axios.get(getProductByIdUrl(id));
};

export const getProducts = (
  page: number = 1,
  perPage: number = 20,
  companyId?: number,
  categoryId?: number,
  active?: number
) => {
  if (companyId) {
    let url = `/api/shop/company/${companyId}/products?page=${page}&per_page=${perPage}`;
    if (active !== undefined) {
      url += `&active=${active}`;
    }
    return axios.get(url);
  }
  if (categoryId) {
    return axios.get(`/api/shop/category/${categoryId}/products?page=${page}&per_page=${perPage}`);
  }
  return axios.get(`${PRODUCTS_URL}?page=${page}&per_page=${perPage}`);
};

export const getProductsByCategoryId = (
  categoryId: number,
  page: number = 1,
  perPage: number = 20
) => {
  return axios.get(`${getProductsByCategory(categoryId)}?page=${page}&per_page=${perPage}`);
};

export const delPhoto = (photoId: number) => {
  return axios.delete(delPhotoFromProduct(photoId));
};

export function setMainPhoto(id: number) {
  return axios.post(setProductMainPhotoUrl(id));
}
