import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { put, takeLatest, call } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IProduct } from '../../interfaces/product';
import {
  createProduct,
  editProduct,
  getProductById,
  getProducts,
  delPhoto,
  deleteProduct,
  setMainPhoto,
} from '../../crud/product.crud';
import { changeMyResponseStatus } from '../../crud/jobResponses.crud';
import { checkout } from '../../crud/profile.crud';
import { actions as myResponsesActions } from './myResponses.duck';

const FETCH_REQUEST = 'products/FETCH_REQUEST';
const FETCH_SUCCESS = 'products/FETCH_SUCCESS';
const FETCH_FAIL = 'products/FETCH_FAIL';
const SET_EMPTY = 'products/SET_EMPTY';

const FETCH_BY_ID_REQUEST = 'products/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'products/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'products/FETCH_BY_ID_FAIL';

const DEL_REQUEST = 'products/DEL_REQUEST';
//const DEL_SUCCESS = 'products/DEL_SUCCESS';
const DEL_FAIL = 'products/DEL_FAIL';
const CLEAR_DEL = 'products/CLEAR_DEL';
const CLEAR_PRODUCTS = 'products/CLEAR_PRODUCTS';

const CLEAR_EDIT = 'products/CLEAR_EDIT';
const ADD_REQUEST = 'products/ADD_REQUEST';
const EDIT_REQUEST = 'products/EDIT_REQUEST';
const EDIT_SUCCESS = 'products/EDIT_SUCCESS';
const EDIT_FAIL = 'products/EDIT_FAIL';
const CLEAR_PRODUCT = 'products/CLEAR_PRODUCT';

const CLEAR_UPLOAD_PHOTO = 'products/CLEAR_UPLOAD_PHOTO';
const UPLOAD_PHOTO_REQUEST = 'products/UPLOAD_PHOTO_REQUEST';
const UPLOAD_PHOTO_SUCCESS = 'products/UPLOAD_PHOTO_SUCCESS';
const UPLOAD_PHOTO_FAIL = 'products/UPLOAD_PHOTO_FAIL';

const CLEAR_DEL_PHOTO = 'products/CLEAR_DEL_PHOTO';
const DEL_PHOTO_REQUEST = 'products//DEL_PHOTO_REQUEST';
const DEL_PHOTO_SUCCESS = 'products/DEL_PHOTO_SUCCESS';
const DEL_PHOTO_FAIL = 'products/DEL_PHOTO_FAIL';

const CLEAR_SET_MAIN_PHOTO = 'products/CLEAR_SET_MAIN_PHOTO';
const SET_MAIN_PHOTO_REQUEST = 'products/SET_MAIN_PHOTO_REQUEST';
const SET_MAIN_PHOTO_SUCCESS = 'products/SET_MAIN_PHOTO_SUCCESS';
const SET_MAIN_PHOTO_FAIL = 'products/SET_MAIN_PHOTO_FAIL';

const CHANGE_STATUS_REQUEST = 'products/CHANGE_STATUS_REQUEST';
const CHANGE_STATUS_SUCCESS = 'products/CHANGE_STATUS_SUCCESS';
const CHANGE_STATUS_FAIL = 'products/CHANGE_STATUS_FAIL';
const CLEAR_CHANGE_STATUS = 'products/CLEAR_CHANGE_STATUS';

const CHECKOUT_REQUEST = 'products/CHECKOUT_REQUEST';
const CHECKOUT_SUCCESS = 'products/CHECKOUT_SUCCESS';
const CHECKOUT_FAIL = 'products/CHECKOUT_FAIL';
const CHECKOUT_CLEAR = 'products/CHECKOUT_CLEAR';

export interface IInitialState {
  page: number;
  perPage: number;
  total: number;
  products: IProduct[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  product: IProduct | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  delError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  photoLoading: boolean;
  photoSuccess: boolean;
  photoError: string | null;

  delPhotoLoading: boolean;
  delPhotoSuccess: boolean;
  delPhotoError: string | null;

  setMainPhotoLoading: boolean;
  setMainPhotoSuccess: boolean;
  setMainPhotoError: string | null;

  changeStatusError: string | null;
  changeStatusSuccess: boolean;

  checkoutLoading: boolean;
  checkoutSuccess: boolean;
  checkoutError: string | null;
}

const defaultPaginatorProps = {
  page: 1,
  perPage: 20,
  total: 0,
};

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  products: undefined,
  loading: false,
  success: false,
  error: null,

  product: undefined,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  delError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  photoLoading: false,
  photoSuccess: false,
  photoError: null,

  delPhotoLoading: false,
  delPhotoSuccess: false,
  delPhotoError: null,

  setMainPhotoLoading: false,
  setMainPhotoSuccess: false,
  setMainPhotoError: null,

  changeStatusError: null,
  changeStatusSuccess: false,

  checkoutLoading: false,
  checkoutSuccess: false,
  checkoutError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'products', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_EMPTY: {
        return { ...state, products: [] };
      }

      case CLEAR_PRODUCTS: {
        return { ...state, products: undefined };
      }

      case CLEAR_PRODUCT: {
        return { ...state, ...defaultPaginatorProps, product: undefined };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          products: undefined,
          loading: true,
          success: false,
          error: null,
          page: action.payload.page,
          perPage: action.payload.perPage,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          total: action.payload.total,
          products: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          product: undefined,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          editLoading: false,
          editError: null,
          editSuccess: false,
        };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true, product: undefined };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case DEL_FAIL: {
        return { ...state, delError: action.payload };
      }

      case CLEAR_DEL: {
        return { ...state, delError: null };
      }

      case CLEAR_UPLOAD_PHOTO: {
        return { ...state, photoLoading: false, photoSuccess: false, photoError: null };
      }

      case UPLOAD_PHOTO_REQUEST: {
        return {
          ...state,
          photoLoading: true,
          photoSuccess: false,
          photoError: null,
        };
      }

      case UPLOAD_PHOTO_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          photoLoading: false,
          photoSuccess: true,
        };
      }

      case UPLOAD_PHOTO_FAIL: {
        return { ...state, photoLoading: false, photoError: action.payload };
      }

      case CLEAR_DEL_PHOTO: {
        return { ...state, delPhotoLoading: false, delPhotoSuccess: false, delPhotoError: null };
      }

      case DEL_PHOTO_REQUEST: {
        return {
          ...state,
          delPhotoLoading: true,
          delPhotoSuccess: false,
          delPhotoError: null,
        };
      }

      case DEL_PHOTO_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          delPhotoLoading: false,
          delPhotoSuccess: true,
        };
      }

      case DEL_PHOTO_FAIL: {
        return {
          ...state,
          delPhotoLoading: false,
          delPhotoError: action.payload,
        };
      }

      case CLEAR_SET_MAIN_PHOTO: {
        return {
          ...state,
          setMainPhotoLoading: false,
          setMainPhotoSuccess: false,
          setMainPhotoError: null,
        };
      }

      case SET_MAIN_PHOTO_REQUEST: {
        return {
          ...state,
          setMainPhotoLoading: true,
          setMainPhotoSuccess: false,
          setMainPhotoError: null,
        };
      }

      case SET_MAIN_PHOTO_SUCCESS: {
        return {
          ...state,
          product: action.payload.data,
          setMainPhotoLoading: false,
          setMainPhotoSuccess: true,
        };
      }

      case SET_MAIN_PHOTO_FAIL: {
        return {
          ...state,
          setMainPhotoLoading: false,
          setMainPhotoError: action.payload,
        };
      }

      case CHANGE_STATUS_SUCCESS: {
        return { ...state, changeStatusError: null, changeStatusSuccess: true };
      }

      case CHANGE_STATUS_FAIL: {
        return { ...state, changeStatusError: action.payload, changeStatusSuccess: false };
      }

      case CLEAR_CHANGE_STATUS: {
        return { ...state, changeStatusError: null, changeStatusSuccess: false };
      }

      case CHECKOUT_REQUEST: {
        return {
          ...state,
          checkoutLoading: true,
          checkoutSuccess: false,
          checkoutError: null,
        };
      }

      case CHECKOUT_SUCCESS: {
        return {
          ...state,
          checkoutLoading: false,
          checkoutSuccess: true,
        };
      }

      case CHECKOUT_FAIL: {
        return {
          ...state,
          checkoutLoading: false,
          checkoutError: action.payload,
        };
      }

      case CHECKOUT_CLEAR: {
        return {
          ...state,
          checkoutError: null,
          checkoutSuccess: false,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: {
    page: number;
    perPage: number;
    companyId?: number;
    categoryId?: number;
    active?: number;
  }) => createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IProduct[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),
  setEmpty: () => createAction(SET_EMPTY),
  clearProducts: () => createAction(CLEAR_PRODUCTS),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IProduct>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  delRequest: (payload: {
    id: number | undefined;
    categoryId: number;
    page: number;
    perPage: number;
  }) => createAction(DEL_REQUEST, payload),
  delFail: (payload: string) => createAction(DEL_FAIL, payload),
  clearDel: () => createAction(CLEAR_DEL),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: { data: FormData }) => createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: FormData }) => createAction(EDIT_REQUEST, payload),
  editSuccess: (payload: IProduct) => createAction(EDIT_SUCCESS, payload),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),
  clearProduct: () => createAction(CLEAR_PRODUCT),

  clearPhotoRequest: () => createAction(CLEAR_UPLOAD_PHOTO),
  uploadPhotoRequest: (payload: { modificationId: number; files: FormData }) =>
    createAction(UPLOAD_PHOTO_REQUEST, payload),
  uploadPhotoSuccess: (payload: IServerResponse<IProduct>) =>
    createAction(UPLOAD_PHOTO_SUCCESS, payload),
  uploadPhotoFail: (payload: string) => createAction(UPLOAD_PHOTO_FAIL, payload),

  clearDelPhotoRequest: () => createAction(CLEAR_DEL_PHOTO),
  delPhotoRequest: (payload: number) => createAction(DEL_PHOTO_REQUEST, payload),
  delPhotoSuccess: (payload: IServerResponse<IProduct>) => createAction(DEL_PHOTO_SUCCESS, payload),
  delPhotoFail: (payload: string) => createAction(DEL_PHOTO_FAIL, payload),

  clearSetMainPhotoRequest: () => createAction(CLEAR_SET_MAIN_PHOTO),
  setMainPhotoRequest: (payload: number) => createAction(SET_MAIN_PHOTO_REQUEST, payload),
  setMainPhotoSuccess: (payload: IServerResponse<IProduct>) =>
    createAction(SET_MAIN_PHOTO_SUCCESS, payload),
  setMainPhotoFail: (payload: string) => createAction(SET_MAIN_PHOTO_FAIL, payload),

  changeStatusRequest: (payload: {
    id: number;
    status: string;
    page?: number;
    perPage?: number;
    productId?: number;
  }) => createAction(CHANGE_STATUS_REQUEST, payload),
  changeStatusFail: (payload: string) => createAction(CHANGE_STATUS_FAIL, payload),
  changeStatusSuccess: () => createAction(CHANGE_STATUS_SUCCESS),
  clearChangeStatus: () => createAction(CLEAR_CHANGE_STATUS),

  checkoutRequest: (payload: {
    id: number;
    data: { buyerStatus: string; comment: string };
    page?: number;
    perPage?: number;
    productId?: number;
  }) => createAction(CHECKOUT_REQUEST, payload),
  checkoutSuccess: (payload: IServerResponse<IProduct>) => createAction(CHECKOUT_SUCCESS, payload),
  checkoutFail: (payload: string) => createAction(CHECKOUT_FAIL, payload),
  checkoutClear: () => createAction(CHECKOUT_CLEAR),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: {
    page: number;
    perPage: number;
    companyId?: number;
    categoryId?: number;
    active?: number;
  };
}) {
  try {
    const { data }: { data: IServerResponse<IProduct[]> } = yield call(() =>
      getProducts(
        payload.page,
        payload.perPage,
        payload.companyId,
        payload.categoryId,
        payload.active
      )
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() => getProductById(payload));
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(e?.response?.data?.message || 'Network error'));
  }
}

function* addSaga({ payload }: { payload: { data: FormData } }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() =>
      createProduct(payload.data)
    );
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: FormData } }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() =>
      editProduct(payload.id, payload.data)
    );
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* delPhotoSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() => delPhoto(payload));
    yield put(actions.delPhotoSuccess(data));
  } catch (e) {
    yield put(actions.delPhotoFail(e?.response?.data?.message || 'Network error'));
  }
}

function* setMainPhotoSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() => setMainPhoto(payload));
    yield put(actions.setMainPhotoSuccess(data));
  } catch (e) {
    yield put(actions.setMainPhotoFail(e?.response?.data?.message || 'Network error'));
  }
}

function* delSaga({
  payload,
}: {
  payload: { id: number | undefined; categoryId: number; page: number; perPage: number };
}) {
  try {
    yield call(() => deleteProduct(payload.id));
    yield put(actions.fetchRequest({ page: payload.page, perPage: payload.perPage }));
  } catch (e) {
    yield put(actions.delFail(e?.response?.data?.message || 'Network error'));
  }
}

function* changeStatus({
  payload,
}: {
  payload: {
    id: number;
    page?: number;
    perPage?: number;
    status: string;
    productId?: number;
  };
}) {
  try {
    yield call(() => changeMyResponseStatus(payload.id, payload.status));

    if (!!payload.productId) {
      yield put(myResponsesActions.fetchMyResForProject({ id: payload.productId }));
    } else if (payload.page && payload.perPage) {
      yield put(actions.fetchRequest({ page: payload.page, perPage: payload.perPage }));
    }
    yield put(actions.changeStatusSuccess());
    yield put(actions.clearChangeStatus());
  } catch (e) {
    yield put(actions.changeStatusFail(e?.response?.data?.message || 'Network error'));
  }
}

function* checkoutSaga({
  payload,
}: {
  payload: {
    id: number;
    data: { buyerStatus: string; comment: string };
    page?: number;
    perPage?: number;
    productId?: number;
  };
}) {
  try {
    const { data }: { data: IServerResponse<IProduct> } = yield call(() =>
      checkout(payload.id, payload.data)
    );
    yield put(actions.checkoutSuccess(data));
    if (!!payload.productId) {
      yield put(actions.fetchByIdRequest(payload.productId));
      yield put(myResponsesActions.fetchMyResForProject({ id: payload.productId }));
    } else if (payload.page && payload.perPage) {
      yield put(actions.fetchRequest({ page: payload.page, perPage: payload.perPage }));
    }
  } catch (e) {
    yield put(actions.checkoutFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.delPhotoRequest>>(DEL_PHOTO_REQUEST, delPhotoSaga);
  yield takeLatest<ReturnType<typeof actions.delRequest>>(DEL_REQUEST, delSaga);
  yield takeLatest<ReturnType<typeof actions.setMainPhotoRequest>>(
    SET_MAIN_PHOTO_REQUEST,
    setMainPhotoSaga
  );
  yield takeLatest<ReturnType<typeof actions.changeStatusRequest>>(
    CHANGE_STATUS_REQUEST,
    changeStatus
  );
  yield takeLatest<ReturnType<typeof actions.checkoutRequest>>(CHECKOUT_REQUEST, checkoutSaga);
}
