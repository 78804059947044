import axios from 'axios';
import {
  GET_USERS_URL,
  CREATE_USER_URL,
  editUserUrl,
  getUserUrl,
  SEND_INVITE_URL,
  delUserUrl,
  uploadUserAvatarUrl,
  ME_URL,
  uploadMeResumeUrl,
  getViewsUrl,
  GET_USERS_CSV,
} from '../constants';
import { IUserAddProps, IUserEditProps } from '../interfaces/user';

export function getUsers(page: number, perPage: number) {
  return axios.get(`${GET_USERS_URL}?page=${page}&per_page=${perPage}`);
}

export function getUsersByRole(
  page: number,
  perPage: number,
  roles: string,
  filter?: {
    ediscoveries: string[];
    education_degree: string[];
    languages: string[];
    country_qualification: string[];
    available_for_work: boolean;
    category_id: string;
  }
) {
  let url = `${GET_USERS_URL}?page=${page}&per_page=${perPage}&roles=${roles}`;

  if (filter) {
    if (filter.ediscoveries.length) {
      url += `&ediscoveries=${filter.ediscoveries.join(',')}`;
    }

    if (filter.education_degree.length) {
      url += `&education_degree=${filter.education_degree.join(',')}`;
    }

    if (filter.country_qualification.length) {
      url += `&country_qualification=${filter.country_qualification.join(',')}`;
    }

    if (filter.languages.length) {
      url += `&languages=${filter.languages.join(',')}`;
    }

    if (filter.available_for_work) {
      url += `&available_for_work=true`;
    }

    if (filter.category_id !== 'all') {
      url += `&category_id=${filter.category_id}`;
    }
  }

  return axios.get(url);
}

export function getUser(id: number) {
  return axios.get(getUserUrl(id));
}

export function createUser(data: IUserAddProps) {
  return axios.post(CREATE_USER_URL, data, {
    params: {
      countries: data.countries_of_qualification?.join(','),
      degrees: data.education_degree?.join(','),
      ediscovery: data.e_discovery_skills?.join(','),
      languages: data.languages?.join(','),
      category_id: data.category_id,
    },
  });
}

export function editUser(id: number, data: IUserEditProps) {
  let url = editUserUrl(id);
  return axios.put(url, data, {
    params: {
      countries: data.countries_of_qualification?.join(','),
      degrees: data.education_degree?.join(','),
      ediscovery: data.e_discovery_skills?.join(','),
      languages: data.languages?.join(','),
      category_id: data.category_id,
    },
  });
}

export function sendInvite(data: string) {
  return axios.get(`${SEND_INVITE_URL}?email=${data}`);
}

export function delUser(id: number) {
  return axios.delete(delUserUrl(id));
}

export function uploadUserAvatar({ photo, id }: { photo: FormData; id: string }) {
  return axios.post(uploadUserAvatarUrl(id), photo);
}

export function getViews() {
  return axios.get(getViewsUrl()).then((data) => {
    console.log(data);
    return data;
  });
}

export function getUsersCsv(type: string) {
  return axios.get(`${GET_USERS_CSV}?roles=${type}`);
}
