import axios from 'axios';
import {
  GET_COMPANIES_URL,
  CREATE_COMPANY_URL,
  SEARCH_COMPANY_URL,
  editCompanyUrl,
  getCompanyUrl,
  delCompanyUrl,
  uploadCompanyLogoUrl,
  getCompanyViewsUrl,
} from '../constants';

import { ICompanyEditProps, ICompanyAddProps } from '../interfaces/companies';

export function getCompanies(
  page: number,
  perPage: number,
  filter: { category_id?: string; services?: [] }
) {
  let url = `${GET_COMPANIES_URL}?page=${page}&per_page=${perPage}`;

  if (filter.services && filter.services.length) {
    url += `&services=${filter.services.join(',')}`;
  }

  if (filter.category_id && filter.category_id !== 'all') {
    url += `&category_id=${filter.category_id}`;
  }

  return axios.get(url);
}

export function getCompany(id: number) {
  return axios.get(getCompanyUrl(id));
}

export function createCompany(data: ICompanyAddProps) {
  return axios.post(CREATE_COMPANY_URL, data);
}

export const searchCompanies = (inn: number) => {
  return axios.get(`${SEARCH_COMPANY_URL}?inn=${inn}`);
};

export function editCompany(id: number, data: ICompanyEditProps) {
  let services: number[] = [];

  if (data.services) {
    services = data.services;
    delete data.services;
  }

  return axios.put(editCompanyUrl(id, services), data);
}

export function delCompany(id: number) {
  return axios.delete(delCompanyUrl(id));
}

export function uploadCompanyLogo({ photo, id }: { photo: FormData; id: string }) {
  return axios.post(uploadCompanyLogoUrl(id), photo);
}

export function getCompaniesViews() {
  // Форматирую данные так как готовый компонент подготовлен на другой interface
  return axios.get(getCompanyViewsUrl()).then((data) => {
    data.data.data = {
      views: data.data.data,
      views_num: data.data.total,
    };
    return data;
  });
}
