import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { put, takeLatest, call } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  IModificationItem,
  IModificationItemToRequest,
} from '../../pages/home/catalog/modifications/interfaces';
import {
  editModification,
  getModifications,
  getModificationById,
  addModification,
  delPhoto,
  setMainPhoto,
} from '../../crud/modifications.crud';
import { uploadPhoto } from '../../crud/photos.crud';

const FETCH_REQUEST = 'modifications/FETCH_REQUEST';
const FETCH_SUCCESS = 'modifications/FETCH_SUCCESS';
const FETCH_FAIL = 'modifications/FETCH_FAIL';

const FETCH_BY_ID_REQUEST = 'modifications/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'modifications/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'modifications/FETCH_BY_ID_FAIL';

const CLEAR_EDIT = 'modifications/CLEAR_EDIT';
const ADD_REQUEST = 'modifications/ADD_REQUEST';
const EDIT_REQUEST = 'modifications/EDIT_REQUEST';
const EDIT_SUCCESS = 'modifications/EDIT_SUCCESS';
const EDIT_FAIL = 'modifications/EDIT_FAIL';

const CLEAR_UPLOAD_PHOTO = 'modifications/CLEAR_UPLOAD_PHOTO';
const UPLOAD_PHOTO_REQUEST = 'modifications/UPLOAD_PHOTO_REQUEST';
const UPLOAD_PHOTO_SUCCESS = 'modifications/UPLOAD_PHOTO_SUCCESS';
const UPLOAD_PHOTO_FAIL = 'modifications/UPLOAD_PHOTO_FAIL';

const CLEAR_DEL_PHOTO = 'modifications/CLEAR_DEL_PHOTO';
const DEL_PHOTO_REQUEST = 'modifications/DEL_PHOTO_REQUEST';
const DEL_PHOTO_SUCCESS = 'modifications/DEL_PHOTO_SUCCESS';
const DEL_PHOTO_FAIL = 'modifications/DEL_PHOTO_FAIL';

const CLEAR_SET_MAIN_PHOTO = 'modifications/CLEAR_SET_MAIN_PHOTO';
const SET_MAIN_PHOTO_REQUEST = 'modifications/SET_MAIN_PHOTO_REQUEST';
const SET_MAIN_PHOTO_SUCCESS = 'modifications/SET_MAIN_PHOTO_SUCCESS';
const SET_MAIN_PHOTO_FAIL = 'modifications/SET_MAIN_PHOTO_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  modifications: IModificationItem[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  modification: IModificationItem | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  photoLoading: boolean;
  photoSuccess: boolean;
  photoError: string | null;

  delPhotoLoading: boolean;
  delPhotoSuccess: boolean;
  delPhotoError: string | null;

  setMainPhotoLoading: boolean;
  setMainPhotoSuccess: boolean;
  setMainPhotoError: string | null;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  modifications: undefined,
  loading: false,
  success: false,
  error: null,

  modification: undefined,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  photoLoading: false,
  photoSuccess: false,
  photoError: null,

  delPhotoLoading: false,
  delPhotoSuccess: false,
  delPhotoError: null,

  setMainPhotoLoading: false,
  setMainPhotoSuccess: false,
  setMainPhotoError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: '  modifications', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REQUEST: {
        return {
          ...state,
          modifications: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        //console.log(action.payload.data);
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          modifications: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          modification: undefined,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        //console.log(action.payload.data);
        return {
          ...state,
          modification: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          editLoading: false,
          editError: null,
          editSuccess: false,
        };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case CLEAR_UPLOAD_PHOTO: {
        return { ...state, photoLoading: false, photoSuccess: false, photoError: null };
      }

      case UPLOAD_PHOTO_REQUEST: {
        return {
          ...state,
          photoLoading: true,
          photoSuccess: false,
          photoError: null,
        };
      }

      case UPLOAD_PHOTO_SUCCESS: {
        return {
          ...state,
          modification: action.payload.data,
          photoLoading: false,
          photoSuccess: true,
        };
      }

      case UPLOAD_PHOTO_FAIL: {
        return { ...state, photoLoading: false, photoError: action.payload };
      }

      case CLEAR_DEL_PHOTO: {
        return { ...state, delPhotoLoading: false, delPhotoSuccess: false, delPhotoError: null };
      }

      case DEL_PHOTO_REQUEST: {
        return {
          ...state,
          delPhotoLoading: true,
          delPhotoSuccess: false,
          delPhotoError: null,
        };
      }

      case DEL_PHOTO_SUCCESS: {
        return {
          ...state,
          modification: action.payload.data,
          delPhotoLoading: false,
          delPhotoSuccess: true,
        };
      }

      case DEL_PHOTO_FAIL: {
        return {
          ...state,
          delPhotoLoading: false,
          delPhotoError: action.payload,
        };
      }

      case CLEAR_SET_MAIN_PHOTO: {
        return {
          ...state,
          setMainPhotoLoading: false,
          setMainPhotoSuccess: false,
          setMainPhotoError: null,
        };
      }

      case SET_MAIN_PHOTO_REQUEST: {
        return {
          ...state,
          setMainPhotoLoading: true,
          setMainPhotoSuccess: false,
          setMainPhotoError: null,
        };
      }

      case SET_MAIN_PHOTO_SUCCESS: {
        return {
          ...state,
          modification: action.payload.data,
          setMainPhotoLoading: false,
          setMainPhotoSuccess: true,
        };
      }

      case SET_MAIN_PHOTO_FAIL: {
        return {
          ...state,
          setMainPhotoLoading: false,
          setMainPhotoError: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: { page: number; perPage: number; id: number }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IModificationItem[]>) =>
    createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IModificationItem>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: { bodyId: number; data: IModificationItemToRequest }) =>
    createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: IModificationItemToRequest }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: () => createAction(EDIT_SUCCESS),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  clearPhotoRequest: () => createAction(CLEAR_UPLOAD_PHOTO),
  uploadPhotoRequest: (payload: { modificationId: number; files: FormData }) =>
    createAction(UPLOAD_PHOTO_REQUEST, payload),
  uploadPhotoSuccess: (payload: IServerResponse<IModificationItem>) =>
    createAction(UPLOAD_PHOTO_SUCCESS, payload),
  uploadPhotoFail: (payload: string) => createAction(UPLOAD_PHOTO_FAIL, payload),

  clearDelPhotoRequest: () => createAction(CLEAR_DEL_PHOTO),
  delPhotoRequest: (payload: number) => createAction(DEL_PHOTO_REQUEST, payload),
  delPhotoSuccess: (payload: IServerResponse<IModificationItem>) =>
    createAction(DEL_PHOTO_SUCCESS, payload),
  delPhotoFail: (payload: string) => createAction(DEL_PHOTO_FAIL, payload),

  clearSetMainPhotoRequest: () => createAction(CLEAR_SET_MAIN_PHOTO),
  setMainPhotoRequest: (payload: number) => createAction(SET_MAIN_PHOTO_REQUEST, payload),
  setMainPhotoSuccess: (payload: IServerResponse<IModificationItem>) =>
    createAction(SET_MAIN_PHOTO_SUCCESS, payload),
  setMainPhotoFail: (payload: string) => createAction(SET_MAIN_PHOTO_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({ payload }: { payload: { page: number; perPage: number; id: number } }) {
  try {
    const { data }: { data: IServerResponse<IModificationItem[]> } = yield call(() =>
      getModifications(payload.page, payload.perPage, payload.id)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IModificationItem> } = yield call(() =>
      getModificationById(payload)
    );
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(e?.response?.data?.message || 'Network error'));
  }
}

function* addSaga({ payload }: { payload: { bodyId: number; data: IModificationItemToRequest } }) {
  try {
    yield call(() => addModification(payload.bodyId, payload.data));
    yield put(actions.editSuccess());
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: IModificationItemToRequest } }) {
  try {
    yield call(() => editModification(payload.id, payload.data));
    yield put(actions.editSuccess());
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* uploadPhotoSaga({ payload }: { payload: { modificationId: number; files: FormData } }) {
  try {
    const { data }: { data: IServerResponse<IModificationItem> } = yield call(() =>
      uploadPhoto(payload.modificationId, payload.files)
    );
    yield put(actions.uploadPhotoSuccess(data));
  } catch (e) {
    yield put(actions.uploadPhotoFail(e?.response?.data?.message || 'Network error'));
  }
}

function* delPhotoSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IModificationItem> } = yield call(() =>
      delPhoto(payload)
    );
    yield put(actions.delPhotoSuccess(data));
  } catch (e) {
    yield put(actions.delPhotoFail(e?.response?.data?.message || 'Network error'));
  }
}

function* setMainPhotoSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IModificationItem> } = yield call(() =>
      setMainPhoto(payload)
    );
    yield put(actions.setMainPhotoSuccess(data));
  } catch (e) {
    yield put(actions.setMainPhotoFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);

  yield takeLatest<ReturnType<typeof actions.uploadPhotoRequest>>(
    UPLOAD_PHOTO_REQUEST,
    uploadPhotoSaga
  );
  yield takeLatest<ReturnType<typeof actions.delPhotoRequest>>(DEL_PHOTO_REQUEST, delPhotoSaga);
  yield takeLatest<ReturnType<typeof actions.setMainPhotoRequest>>(
    SET_MAIN_PHOTO_REQUEST,
    setMainPhotoSaga
  );
}
