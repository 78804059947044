import axios from 'axios';
import {
  LOGIN_URL,
  REGISTER_URL,
  ME_URL,
  getRecoveryPasswordUrl,
  NEW_PASSWORD_URL,
  getRegisterURL,
} from '../constants';
import { IUserForRegister } from '../pages/auth/interfaces';

export function login(login: string, password: string) {
  let formData = new FormData();
  formData.append('login', login);
  formData.append('password', password);
  return axios.post(LOGIN_URL, formData);
}

// export function register(email: string, fullname: string, username: string, password: string) {
// return axios.post(REGISTER_URL, { email, fullname, username, password });
// }

export const register = (data: IUserForRegister, status: boolean) => {
  return axios.post(getRegisterURL(status), data);
};

export function recoveryPassword(email: string) {
  return axios.get(getRecoveryPasswordUrl(email));
}

export function newPassword(data: { password: string; password2: string; code: string }) {
  return axios.post(NEW_PASSWORD_URL, data);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
