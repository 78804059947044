import { IntlShape } from 'react-intl';

import { UserRoles, UserRoleType } from '../../../../../interfaces/user';
import { userListType, buyer_role, vendor_role, manager_role, all_role } from '../../constants';

export const getRoleName = (roles: [UserRoleType], intl?: IntlShape): string => {
  if (roles.includes(UserRoles.ROLE_ADMIN)) {
    return intl ? intl.formatMessage({ id: 'USER.ROLES.ADMIN' }) : UserRoles.ROLE_ADMIN;
  } else if (roles.includes(UserRoles.ROLE_MANAGER)) {
    return intl ? intl.formatMessage({ id: 'USER.ROLES.MANAGER' }) : UserRoles.ROLE_MANAGER;
  }
  if (roles.includes(UserRoles.ROLE_VENDOR)) {
    return intl ? intl.formatMessage({ id: 'USER.ROLES.VENDOR' }) : UserRoles.ROLE_VENDOR;
  }
  return intl ? intl.formatMessage({ id: 'USER.ROLES.BUYER' }) : UserRoles.ROLE_BUYER;
};

export const getRole = (type: string) => {
  switch (type) {
    case userListType.BUYER:
      return buyer_role;
    case userListType.MANAGER:
      return manager_role;
    case userListType.VENDOR:
      return vendor_role;
    default:
      return all_role;
  }
};
