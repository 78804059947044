import axios from 'axios';
import {
  getAdvantageUrl,
  createPublicationUrl,
  getPublicationUrl,
  deletePublicationUrl,
  getAllPublicationsUrl,
  getMyPublicationsUrl,
  createPublicationLogoUrl,
  editPublicationUrl,
  createPublicationPhoto,
} from '../constants';
import { ICreatePublication, IEditPublication } from '../pages/home/blog/interfaces';

export function createPublication(data: FormData) {
  return axios.post(createPublicationPhoto(), data);
}
export function editPublication(id: number, data: IEditPublication) {
  return axios.put(editPublicationUrl(id), data);
}
export function getPublication(id: number) {
  return axios.get(getPublicationUrl(id));
}
export function deletePublication(id: number) {
  return axios.delete(deletePublicationUrl(id));
}
export function getPublicationsUrl(data: { page: number; perPage: number; my?: boolean }) {
  if (data.my) {
    return axios.get(getMyPublicationsUrl(data));
  } else {
    return axios.get(getAllPublicationsUrl(data));
  }
}
export function getAllPublications(data: { page: number; perPage: number }) {
  return axios.get(getAllPublicationsUrl(data));
}
export function getMyPublications(data: { page: number; perPage: number }) {
  return axios.get(getMyPublicationsUrl(data));
}
export function createPublicationLogo(id: number, file: FormData) {
  return axios.post(createPublicationLogoUrl(id), file);
}


