// import React from 'react';
// import UsersFilter from '../components/UsersFilter/UsersFilter';
// import UsersCatalogFilter from '../components/CatalogFilter/UsersCatalogFilter';
// import CompanyCatalogFilter from '../components/CatalogFilter/CompanyCatalogFilter';

import ProjectsIconBlue from '../assets/icons/aside/ProjectsBtn(L-Blue).svg';
import ProjectsIconOrange from '../assets/icons/aside/ProjectsBtn(Orange).svg';

import CompanyIcon from '../assets/icons/aside/CompanyBtn(L-Blue).svg';
import UsersIcon from '../assets/icons/aside/UsersBtn(L-Blue).svg';

import SettingsIconBlue from '../assets/icons/aside/SettingsBtn(L-Blue).svg';
import SettingsIconOrange from '../assets/icons/aside/SettingsBtn(Orange).svg';

import DocumentsIcon from '../assets/icons/aside/DocumentsBtn(L-Blue).svg';
import ExitIcon from '../assets/icons/aside/ExitBtn(L-Blue).svg';

import SearchIcon from '../assets/icons/aside/searchSide.svg';

export const menuConfigItems = {
  profile: {
    root: true,
    title: 'SUBMENU.PROFILE',
    translate: 'SUBMENU.PROFILE',
    page: 'settings/profile',
    image: UsersIcon,
  },

  buyerProfile: {
    root: true,
    title: 'MENU.SETTINGS',
    translate: 'MENU.SETTINGS',
    page: 'settings/profile',
    bullet: 'dot',
    image: SettingsIconOrange,
    submenu: [
      {
        title: 'SUBMENU.PROFILE',
        page: 'settings/profile',
        translate: 'SUBMENU.PROFILE',
      },
      {
        title: 'SUBMENU.NOTIFICATIONS',
        translate: 'SUBMENU.NOTIFICATIONS',
        page: 'notifications',
      },
    ],
  },

  logout: {
    root: true,
    title: 'MENU.LOGOUT',
    translate: 'MENU.LOGOUT',
    page: 'logout',
    image: ExitIcon,
  },

  products: {
    title: 'MENU.PRODUCTS',
    translate: 'MENU.PRODUCTS',
    bullet: 'dot',
    root: true,
    image: ProjectsIconBlue,
    submenu: [
      {
        title: 'Скидки компании',
        page: 'products/list',
        translate: 'CATEGORIES.TABLE.TOOLTIP.PRODUCTS',
      },
    ],
  },

  buyerProducts: {
    title: 'MENU.PRODUCTS',
    translate: 'MENU.PRODUCTS',
    bullet: 'dot',
    root: true,
    image: ProjectsIconOrange,
    submenu: [
      {
        title: 'Catalog of New Projects',
        page: 'products/list',
        translate: 'CATEGORIES.TABLE.TOOLTIP.PRODUCTS',
      },
      {
        title: 'My Responses & Messages',
        page: 'products/myresponses',
        translate: 'CATEGORIES.TABLE.TOOLTIP.MY_RESPONSES',
      },
    ],
  },

  vendorProducts: {
    title: 'MENU.PRODUCTS',
    translate: 'MENU.PRODUCTS',
    bullet: 'dot',
    root: true,
    image: ProjectsIconBlue,
    submenu: [
      {
        title: 'Catalog of New Projects',
        page: 'products/list',
        translate: 'Catalog of New Projects',
      },
      {
        title: 'My Projects & Messages',
        page: 'products/my/list',
        translate: 'My Projects & Messages',
      },
    ],
  },

  vendorCompany: {
    root: true,
    title: 'MENU.COMPANIES',
    translate: 'MENU.COMPANIES',
    bullet: 'dot',
    image: CompanyIcon,
    submenu: [],
  },

  vendorProfile: {
    root: true,
    title: 'MENU.MY_PROFILES',
    bullet: 'dot',
    translate: 'MENU.MY_PROFILES',
    image: UsersIcon,
    submenu: [
      {
        title: 'SUBMENU.PROFILE',
        translate: 'SUBMENU.PROFILE',
        page: 'settings/profile',
      },
      {
        title: 'SUBMENU.NOTIFICATIONS',
        translate: 'SUBMENU.NOTIFICATIONS',
        page: 'notifications',
      },
      {
        translate: 'SUBMENU.MY_COMPANY_PROFILE',
        title: 'SUBMENU.MY_COMPANY_PROFILE',
        page: 'companies/list',
      },
      {
        translate: 'MENU.CATALOG.STORES',
        title: 'MENU.CATALOG.STORES',
        page: 'stores/list',
      },
    ],
  },

  orders: {
    root: true,
    title: 'Заказы',
    translate: 'MENU.ORDERS',
    bullet: 'dot',
    image: ProjectsIconBlue,
    submenu: [
      { translate: 'SUBMENU.ALLORDERS', title: 'SUBMENU.ALLORDERS', page: 'orders/list/full' },
      {
        translate: 'SUBMENU.ABANDONEDCHECKOUTS',
        title: 'UBMENU.ABANDONEDCHECKOUTS',
        page: 'orders/list/abandoned',
      },
    ],
  },

  companies: {
    root: true,
    title: 'MENU.COMPANIES',
    translate: 'MENU.COMPANIES',
    bullet: 'dot',
    image: CompanyIcon,
    submenu: [
      { translate: 'SUBMENU.COMPANIES', title: 'SUBMENU.COMPANIES', page: 'companies/list' },
    ],
  },

  users: {
    root: true,
    title: 'MENU.USERS',
    translate: 'MENU.USERS',
    bullet: 'dot',
    image: UsersIcon,
    submenu: [
      // { translate: 'SUBMENU.USER.ADD', title: 'SUBMENU.USER.ADD', page: 'users/new' },
      // {
      // translate: 'SUBMENU.CUSTOMERS',
      // title: 'SUBMENU.CUSTOMERS',
      // page: 'users/buyers',
      // },
      { translate: 'SUBMENU.TEAM', title: 'SUBMENU.TEAM', page: 'users/managers' },
      {
        translate: 'SUBMENU.PROVIDERS',
        title: 'SUBMENU.PROVIDERS',
        page: 'users/vendors',
      },
      {
        translate: 'SUBMENU.FREELANCERS',
        title: 'SUBMENU.FREELANCERS',
        page: 'users/buyers',
        // filter: () => <UsersFilter />,
        filter: 'UsersFilter',
      },
      // {
      //   translate: 'SUBMENU.COMPANIES.ADD',
      //   title: 'SUBMENU.COMPANIES.ADD',
      //   page: 'companies/new',
      //   exact: true,
      // },
    ],
  },

  settings: {
    root: true,
    title: 'MENU.SETTINGS',
    bullet: 'dot',
    translate: 'MENU.SETTINGS',
    image: SettingsIconBlue,
    submenu: [
      {
        title: 'SUBMENU.PROFILE',
        translate: 'SUBMENU.PROFILE',
        page: 'settings/profile',
      },
      {
        title: 'SUBMENU.NOTIFICATIONS',
        translate: 'SUBMENU.NOTIFICATIONS',
        page: 'notifications',
      },
    ],
  },

  legal: {
    root: true,
    title: 'SUBMENU.LEGAL',
    bullet: 'dot',
    translate: 'SUBMENU.LEGAL',
    image: DocumentsIcon,
    submenu: [
      {
        title: 'SUBMENU.LEGAL_TERMS',
        translate: 'SUBMENU.LEGAL_TERMS',
        page: 'documents/legacy',
      },
      {
        title: 'SUBMENU.LEGAL_PRIVACY',
        translate: 'SUBMENU.LEGAL_PRIVACY',
        page: 'documents/privacy',
      },
    ],
  },

  categories: {
    root: true,
    title: 'SUBMENU.CATEGORY',
    translate: 'SUBMENU.CATEGORY',
    bullet: 'dot',
    image: ProjectsIconBlue,
    submenu: [
      {
        title: 'Дерево категорий компании',
        page: 'categories/list/companies',
        translate: 'SUBMENU.COMPANY_CATEGORIES',
      },
      {
        title: 'Дерево категорий услуг',
        page: 'categories/list/company_services',
        translate: 'SUBMENU.SERVICES_CATEGORIES',
      },
      {
        title: 'Дерево категорий пользователей',
        page: 'categories/list/users',
        translate: 'SUBMENU.USERS_CATEGORIES',
      },
    ],
  },

  catalog: {
    root: true,
    title: 'SUBMENU.CATALOG',
    translate: 'SUBMENU.CATALOG',
    bullet: 'dot',
    image: SearchIcon,
    submenu: [
      {
        title: 'SUBMENU.CATALOG.COMPANY',
        page: 'companies/catalog',
        translate: 'SUBMENU.CATALOG.COMPANY',
        // filter: () => <CompanyCatalogFilter />,
        filter: 'CompanyCatalogFilter',
      },
      {
        title: 'SUBMENU.CATALOG.USERS',
        page: 'users/catalog',
        translate: 'SUBMENU.CATALOG.USERS',
        // filter: () => (
        //   <div>
        //     <UsersCatalogFilter />
        //     <UsersFilter />,
        //   </div>
        // ),
        filter: 'UsersCatalogFilter',
      },
    ],
  },

  adminTariffs: {
    root: true,
    title: 'SUBMENU.TARIFFS',
    translate: 'SUBMENU.TARIFFS',
    bullet: 'dot',
    image: ProjectsIconBlue,
    submenu: [
      {
        title: 'SUBMENU.TARIFFS.FREELANCERS',
        page: 'tariffs/list/freelancers',
        translate: 'SUBMENU.TARIFFS.FREELANCERS',
      },
      {
        title: 'SUBMENU.TARIFFS.PROVIDERS',
        page: 'tariffs/list/providers',
        translate: 'SUBMENU.TARIFFS.PROVIDERS',
      },
    ],
  },

  freelancersTariffs: {
    root: true,
    title: 'SUBMENU.TARIFFS',
    translate: 'SUBMENU.TARIFFS',
    bullet: 'dot',
    page: 'tariffs/list/freelancers',
    image: ProjectsIconBlue,
  },

  providersTariffs: {
    root: true,
    title: 'SUBMENU.TARIFFS',
    translate: 'SUBMENU.TARIFFS',
    page: 'tariffs/list/providers',
    image: ProjectsIconBlue,
  },

  providersBlog: {
    root: true,
    title: 'SUBMENU.BLOG',
    translate: 'SUBMENU.BLOG',
    bullet: 'dot',
    image: ProjectsIconBlue,
    submenu: [
      {
        title: 'SUBMENU.BLOG.ALL',
        page: 'blog/all',
        translate: 'SUBMENU.BLOG.ALL',
      },
      {
        title: 'SUBMENU.BLOG.MY',
        page: 'blog/my',
        translate: 'SUBMENU.BLOG.MY',
      },
    ],
  },

  guestBlog: {
    root: true,
    title: 'SUBMENU.BLOG.ALL',
    translate: 'SUBMENU.BLOG.ALL',
    bullet: 'dot',
    image: ProjectsIconBlue,
    page: 'blog/newsfeed',
  },

  buyerBlog: {
    root: true,
    title: 'SUBMENU.BLOG',
    translate: 'SUBMENU.BLOG',
    bullet: 'dot',
    image: ProjectsIconOrange,
    submenu: [
      {
        title: 'SUBMENU.BLOG.ALL',
        page: 'blog/all',
        translate: 'SUBMENU.BLOG.ALL',
      },
      {
        title: 'SUBMENU.BLOG.MY',
        page: 'blog/my',
        translate: 'SUBMENU.BLOG.MY',
      },
    ],
  },

  login: {
    root: true,
    title: 'MENU.LOGIN',
    translate: 'MENU.LOGIN',
    bullet: 'dot',
    image: ExitIcon,
    page: 'auth',
  },
};
