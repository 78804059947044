import { ICompany } from './companies';
import { CountriesType, EducationsType, LanguagesType, SkillsType } from './attributes';
import { ICategoryItem } from '../pages/home/categories/interfaces';
import { ITariff } from './tariff';
import { ILocation } from './locations';

export interface IUserViews {
  views_num: number;
  current_user_tariff: ICurrentUserTariff;
  views?: {
    id: number;
    company: {
      id: number;
      name: string;
      logo: string | null;
    };
    amount: number;
    last_view_at: Date;
  }[];
}

export interface ICurrentUserTariff {
  displaying_other_contacts: number;
  id: number;
  publish_expert_articles: number;
  responses_per_month: number;
  showing_contacts_to_other_members: number;
  start_datetime: Date;
  stop_datetime: Date;
  tariff: ITariff;
  write_to_another_member: number;
}

export interface IUserAddProps {
  login: string | undefined;
  email: string | undefined;
  password: string;
  roles: string[];
  fio: string | undefined;
  countries_of_qualification?: CountriesType[];
  education_degree?: EducationsType[];
  e_discovery_skills?: SkillsType[];
  languages?: LanguagesType[];
  category_id?: string;
}

export interface IUserEditProps {
  login: string | undefined;
  email: string | undefined;
  password?: string;
  fio: string | undefined;
  first_name?: string;
  last_name?: string;
  active?: boolean;
  about?: string | null;
  education?: string | null;
  country?: string | null;
  experience?: string | null;
  skills?: string | null;
  short_about?: string | null;
  phone?: string | null;
  available_for_work?: boolean;
  location?: ILocation | { address: string } | null;

  countries_of_qualification?: CountriesType[];
  education_degree?: EducationsType[];
  e_discovery_skills?: SkillsType[];
  languages?: LanguagesType[];
  category_id?: string;
}

export interface IUser {
  id: number;
  email: string;
  api_token: string;
  fio: string;
  first_name: string;
  last_name: string;
  login: string;
  is_admin: boolean;
  active: boolean;
  company: ICompany | null;
  roles: [UserRoleType];
  phone: string;
  middleName: string;
  birthday: Date;
  gender: string;
  about: string;
  avatar: string;
  education: string;
  country: string;
  experience: string;
  skills: string;
  short_about: string;
  available_for_work: boolean;

  countries_of_qualification: CountriesType[];
  education_degree: EducationsType[];
  e_discovery_skills: SkillsType[];
  languages: LanguagesType[];
  category?: ICategoryItem;
  current_user_tariff: ICurrentUserTariff;
  location: ILocation | null;
  resume_file: string;
  views_num: number;
  notifications: { type: string }[];
}

export enum UserRoles {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_BUYER = 'ROLE_BUYER',
  ROLE_USER = 'ROLE_USER',
  ROLE_VENDOR = 'ROLE_VENDOR',
}

export type UserRoleType = 'ROLE_ADMIN' | 'ROLE_MANAGER' | 'ROLE_BUYER' | 'ROLE_VENDOR';
