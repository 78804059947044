import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { getRoleName } from '../../pages/home/users/users/utils';
import { API_DOMAIN } from '../../constants';
import { getFullname } from '../../utils/user';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headerBtn: {
    marginLeft: theme.spacing(1),
  },
}));

const UserProfile = ({ me, loading, showHi, showAvatar }) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles();

  if (loading) return null

  return me ? (
    <Link to="/settings/profile" className="kt-header__topbar-item kt-header__topbar-item--user">
      <div id="dropdown-toggle-user-profile">
        <div className="kt-header__topbar-user">
          <>
            <div className="kt-header__topbar-container kt-hidden-mobile">
              {showHi && (
                <span className="kt-header__topbar-username">{getFullname(me) || me.email}</span>
              )}
              <span className="kt-header__topbar-role">{getRoleName(me.roles, intl)}</span>
            </div>

            {showAvatar && me.avatar && (
              <div
                style={{
                  width: 35,
                  height: 35,
                  maxHeight: 35,
                  borderRadius: ' 50%',
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                  backgroundColor: '#efefef',
                }}
              >
                <img
                  style={{
                    verticalAlign: 'middle',
                    width: '100%',
                    height: '100%',
                    maxHeight: 40,
                    borderRadius: 0,
                    objectFit: 'cover',
                  }}
                  alt="Pic"
                  src={`${API_DOMAIN}/${me.avatar}`}
                />
              </div>
            )}
          </>
        </div>
      </div>
    </Link>
  ) : (
    <>
      <div className="kt-login__signup">
        <span className="kt-login__signup-label">
          {intl.formatMessage({ id: 'AUTH.GENERAL.NO_ACCOUNT' })}
        </span>
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            history.push('/auth/registration');
          }}
          className={classes.headerBtn}
          variant="contained"
          color="secondary"
        >
          {intl.formatMessage({ id: 'AUTH.GENERAL.SIGNUP_BUTTON' })}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = ({ profile: { me, loading } }) => ({
  me,
	loading
});

export default connect(mapStateToProps)(UserProfile);
