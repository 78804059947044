import { API_DOMAIN } from '../../app/constants';
import {actions} from '../../app/store/ducks/auth.duck';

export function removeCSSClass(ele, cls) {
  const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
  ele.className = ele.className.replace(reg, ' ');
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, store) {
  axios.defaults.baseURL = API_DOMAIN;
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.common['X-AUTH-TOKEN'] = authToken;
      }
      return config;
    },
    err => Promise.reject(err)
  );

  // handle empty responses from server
  axios.interceptors.response.use(null, (error) => {
    if (!error?.response) {
      const { auth: { emptyResErrAttempts } } = store.getState();

      if (emptyResErrAttempts >= 3) {
        store.dispatch(actions.setEmptyResErrAttempts(0));
        return Promise.reject(error);
      };

      return new Promise(async resolve => {
        // console.log('resend after 1 second...');
        await new Promise(r => setTimeout(r, 1000));
        // console.log('attempt', emptyResErrAttempts);

        store.dispatch(actions.setEmptyResErrAttempts(emptyResErrAttempts + 1));
        resolve(Promise.resolve(axios.request(error.config)));
      })
    } else {
      return Promise.reject(error);
    }

  });
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, '');
    localStorage.setItem(key + '_expiresIn', '');
  } catch (e) {
    console.log(
      'removeStorage: Error removing key [' + key + '] from localStorage: ' + JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + '_expiresIn');
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        'getStorage: Error reading key [' + key + '] from localStorage: ' + JSON.stringify(e)
      );
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + '_expiresIn', schedule);
  } catch (e) {
    console.log(
      'setStorage: Error setting key [' + key + '] in localStorage: ' + JSON.stringify(e)
    );
    return false;
  }
  return true;
}
