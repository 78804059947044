import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IUser, IUserEditProps, IUserViews } from '../../interfaces/user';
import {
  addNotifications,
  deleteMe,
  deleteResume,
  editMe,
  getMe,
  removeNotifications,
  uploadAvatar,
  uploadResume,
} from '../../crud/profile.crud';
import { getViews } from '../../crud/users.crud';
// import { IProduct } from '../../interfaces/product';

const CLEAR_ME = 'profile/CLEAR_ME';
const FETCH_REQUEST = 'profile/FETCH_REQUEST';
const FETCH_SUCCESS = 'profile/FETCH_SUCCESS';
const FETCH_FAIL = 'profile/FETCH_FAIL';

const CLEAR_EDIT = 'profile/CLEAR_EDIT';
const EDIT_REQUEST = 'profile/EDIT_REQUEST';
const EDIT_NOTIFICATIONS_REQUEST = 'profile/EDIT_NOTIFICATIONS_REQUEST';
const EDIT_NOTIFICATIONS_SUCCESS = 'profile/EDIT_NOTIFICATIONS_SUCCESS';
const EDIT_NOTIFICATIONS_FAIL = 'profile/EDIT_NOTIFICATIONS_FAIL';
const EDIT_SUCCESS = 'profile/EDIT_SUCCESS';
const EDIT_FAIL = 'profile/EDIT_FAIL';

// const CHECKOUT_REQUEST = 'products/CHECKOUT_REQUEST';
// const CHECKOUT_SUCCESS = 'products/CHECKOUT_SUCCESS';
// const CHECKOUT_FAIL = 'products/CHECKOUT_FAIL';
// const CHECKOUT_CLEAR = 'products/CHECKOUT_CLEAR';

const UPLOAD_AVATAR_REQUEST = 'profile/UPLOAD_AVATAR_REQUEST';
const UPLOAD_AVATAR_SUCCESS = 'profile/UPLOAD_AVATAR_SUCCESS';
const UPLOAD_AVATAR_FAIL = 'profile/UPLOAD_AVATAR_FAIL';

const DELETE_CLEAR = 'profile/DELETE_CLEAR';
const DELETE_REQUEST = 'profile/DELETE_REQUEST';
const DELETE_SUCCESS = 'profile/DELETE_SUCCESS';
const DELETE_FAIL = 'profile/DELETE_FAIL';

const UPLOAD_RESUME_REQUEST = 'profile/UPLOAD_RESUME_REQUEST';
const UPLOAD_RESUME_SUCCESS = 'profile/UPLOAD_RESUME_SUCCESS';
const UPLOAD_RESUME_FAIL = 'profile/UPLOAD_RESUME_FAIL';

const DELETE_RESUME_REQUEST = 'profile/DELETE_RESUME_REQUEST';
const DELETE_RESUME_SUCCESS = 'profile/DELETE_RESUME_SUCCESS';
const DELETE_RESUME_FAIL = 'profile/DELETE_RESUME_FAIL';

const CLEAR_VIEWS = 'profile/CLEAR_VIEWS';
const FETCH_VIEWS_REQUEST = 'profile/FETCH_VIEWS_REQUEST';
const FETCH_VIEWS_SUCCESS = 'profile/FETCH_VIEWS_SUCCESS';
const FETCH_VIEWS_FAIL = 'profile/FETCH_VIEWS_FAIL';

export interface IInitialState {
  me: IUser | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  deleteLoading: boolean;
  deleteSuccess: boolean;
  deleteError: string | null;

  editNotificationsLoading: boolean;
  editNotificationsSuccess: boolean;
  editNotificationsError: string | null;

  // checkoutLoading: boolean;
  // checkoutSuccess: boolean;
  // checkoutError: string | null;

  uploadAvatarLoading: boolean;
  uploadAvatarSuccess: boolean;
  uploadAvatarError: string | null;

  uploadResumeLoading: boolean;
  uploadResumeSuccess: boolean;
  uploadResumeError: string | null;

  deleteResumeLoading: boolean;
  deleteResumeSuccess: boolean;
  deleteResumeError: string | null;

  views: IUserViews | null;
  viewsLoading: boolean;
  viewsSuccess: boolean;
  viewsError: string | null;
}

const initialState: IInitialState = {
  me: undefined,
  loading: false,
  success: false,
  error: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: null,

  editNotificationsLoading: false,
  editNotificationsSuccess: false,
  editNotificationsError: null,

  // checkoutLoading: false,
  // checkoutSuccess: false,
  // checkoutError: null,

  uploadAvatarLoading: false,
  uploadAvatarSuccess: false,
  uploadAvatarError: null,

  uploadResumeLoading: false,
  uploadResumeSuccess: false,
  uploadResumeError: null,

  deleteResumeLoading: false,
  deleteResumeSuccess: false,
  deleteResumeError: null,

  views: null,
  viewsLoading: false,
  viewsSuccess: false,
  viewsError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'profile', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_ME: {
        return { ...state, me: undefined, loading: false, success: false };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          me: undefined,
          loading: true,
          error: null,
          success: false,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          me: action.payload.data.id ? action.payload.data : undefined,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload, success: false };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          editLoading: false,
          editError: null,
          editSuccess: false,
          editNotificationsLoading: false,
          editNotificationsError: null,
          editNotificationsSuccess: false,
        };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editError: null, editSuccess: false };
      }

      case EDIT_SUCCESS: {
        return {
          ...state,
          editLoading: false,
          me: {
            ...state.me,
            ...action.payload.data,
          },
          editSuccess: true,
        };
      }

      case EDIT_NOTIFICATIONS_REQUEST: {
        return {
          ...state,
          editNotificationsLoading: true,
          editError: null,
          editNotificationsSuccess: false,
        };
      }

      case EDIT_NOTIFICATIONS_SUCCESS: {
        return {
          ...state,
          editNotificationsLoading: false,
          editNotificationsSuccess: true,
        };
      }

      case EDIT_NOTIFICATIONS_FAIL: {
        return {
          ...state,
          editNotificationsLoading: false,
          editNotificationsSuccess: false,
          editNotificationsError: action.payload,
        };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editSuccess: false, editError: action.payload };
      }

      case DELETE_CLEAR: {
        return { ...state, deleteLoading: false, deleteError: null, deleteSuccess: false };
      }

      case DELETE_REQUEST: {
        return { ...state, deleteLoading: true, deleteError: null, deleteSuccess: false };
      }

      case DELETE_SUCCESS: {
        return { ...state, deleteSuccess: true, deleteLoading: false };
      }

      case DELETE_FAIL: {
        return {
          ...state,
          deleteLoading: false,
          deleteSuccess: false,
          deleteError: action.payload,
        };
      }

      // case CHECKOUT_REQUEST: {
      // return {
      // ...state,
      // checkoutLoading: true,
      // checkoutSuccess: false,
      // checkoutError: null,
      // };
      // }

      // case CHECKOUT_SUCCESS: {
      // return {
      // ...state,
      // checkoutLoading: false,
      // checkoutSuccess: true,
      // };
      // }

      // case CHECKOUT_FAIL: {
      // return {
      // ...state,
      // checkoutLoading: false,
      // checkoutError: action.payload,
      // };
      // }

      // case CHECKOUT_CLEAR: {
      // return {
      // ...state,
      // checkoutError: null,
      // };
      // }

      case UPLOAD_AVATAR_REQUEST: {
        return {
          ...state,
          uploadAvatarLoading: true,
          uploadAvatarError: null,
          uploadAvatarSuccess: false,
        };
      }

      case UPLOAD_AVATAR_SUCCESS: {
        return {
          ...state,
          me: action.payload.data,
          uploadAvatarLoading: false,
          uploadAvatarError: null,
          uploadAvatarSuccess: true,
        };
      }

      case UPLOAD_AVATAR_FAIL: {
        return {
          ...state,
          uploadAvatarLoading: false,
          uploadAvatarError: action.payload,
          uploadAvatarSuccess: false,
        };
      }

      case UPLOAD_RESUME_REQUEST: {
        return {
          ...state,
          uploadResumeLoading: true,
          uploadResumeError: null,
          uploadResumeSuccess: false,
        };
      }

      case UPLOAD_RESUME_SUCCESS: {
        return {
          ...state,
          me: action.payload.data,
          uploadResumeLoading: false,
          uploadResumeError: null,
          uploadResumeSuccess: true,
        };
      }

      case UPLOAD_RESUME_FAIL: {
        return {
          ...state,
          uploadResumeLoading: false,
          uploadResumeError: action.payload,
          uploadResumeSuccess: false,
        };
      }

      case DELETE_RESUME_REQUEST: {
        return {
          ...state,
          deleteResumeLoading: true,
          deleteResumeError: null,
          deleteResumeSuccess: false,
        };
      }

      case DELETE_RESUME_SUCCESS: {
        return {
          ...state,
          me: action.payload.data,
          deleteResumeLoading: false,
          deleteResumeError: null,
          deleteResumeSuccess: true,
        };
      }

      case DELETE_RESUME_FAIL: {
        return {
          ...state,
          deleteResumeLoading: false,
          deleteResumeError: action.payload,
          deleteResumeSuccess: false,
        };
      }

      case CLEAR_VIEWS: {
        return {
          ...state,
          viewsLoading: false,
          viewsError: null,
          viewsSuccess: false,
          views: null,
        };
      }

      case FETCH_VIEWS_REQUEST: {
        return { ...state, viewsLoading: true, viewsError: null, viewsSuccess: false };
      }

      case FETCH_VIEWS_SUCCESS: {
        return { ...state, viewsLoading: false, viewsSuccess: true, views: action.payload.data };
      }

      case FETCH_VIEWS_FAIL: {
        return { ...state, viewsLoading: false, viewsError: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearMe: () => createAction(CLEAR_ME),
  fetchRequest: () => createAction(FETCH_REQUEST),
  fetchSuccess: (payload: IServerResponse<IUser>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  editRequest: (payload: IUserEditProps) => createAction(EDIT_REQUEST, payload),
  editNotificationsRequest: (payload: { add: string; remove: string }) =>
    createAction(EDIT_NOTIFICATIONS_REQUEST, payload),
  editSuccess: (payload: IServerResponse<IUser>) => createAction(EDIT_SUCCESS, payload),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),
  editNotificationsSuccess: () => createAction(EDIT_NOTIFICATIONS_SUCCESS),
  editNotificationsFail: (payload: string) => createAction(EDIT_NOTIFICATIONS_FAIL, payload),
  clearDelete: () => createAction(DELETE_CLEAR),
  deleteRequest: (payload: { id: number }) => createAction(DELETE_REQUEST, payload),
  deleteSuccess: () => createAction(DELETE_SUCCESS),
  deleteFail: (payload: string) => createAction(DELETE_FAIL, payload),

  // checkoutRequest: (payload: { id: number; data: { buyerStatus: string; comment: string } }) =>
  // createAction(CHECKOUT_REQUEST, payload),
  // checkoutSuccess: (payload: IServerResponse<IProduct>) => createAction(CHECKOUT_SUCCESS, payload),
  // checkoutFail: (payload: string) => createAction(CHECKOUT_FAIL, payload),
  // checkoutClear: () => createAction(CHECKOUT_CLEAR),

  uploadAvatarRequest: (payload: FormData) => createAction(UPLOAD_AVATAR_REQUEST, payload),
  uploadAvatarSuccess: (payload: IServerResponse<IUser>) =>
    createAction(UPLOAD_AVATAR_SUCCESS, payload),
  uploadAvatarFail: (payload: string) => createAction(UPLOAD_AVATAR_FAIL, payload),

  uploadResumeRequest: (payload: FormData) => createAction(UPLOAD_RESUME_REQUEST, payload),
  uploadResumeSuccess: (payload: IServerResponse<IUser>) =>
    createAction(UPLOAD_RESUME_SUCCESS, payload),
  uploadResumeFail: (payload: string) => createAction(UPLOAD_RESUME_FAIL, payload),

  deleteResumeRequest: () => createAction(DELETE_RESUME_REQUEST),
  deleteResumeSuccess: (payload: IServerResponse<IUser>) =>
    createAction(DELETE_RESUME_SUCCESS, payload),
  deleteResumeFail: (payload: string) => createAction(DELETE_RESUME_FAIL, payload),

  clearViews: () => createAction(CLEAR_VIEWS),
  viewsRequest: () => createAction(FETCH_VIEWS_REQUEST),
  viewsSuccess: (payload: IServerResponse<IUserViews>) =>
    createAction(FETCH_VIEWS_SUCCESS, payload),
  viewsFail: (payload: string) => createAction(FETCH_VIEWS_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga() {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => getMe());
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editSaga({ payload }: { payload: IUserEditProps }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => editMe(payload));
    yield put(actions.editSuccess(data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editNotificationsSaga({ payload }: { payload: { add: string; remove: string } }) {
  try {
    if (Boolean(payload.add)) {
      yield call(() => addNotifications(payload.add));
    }
    if (Boolean(payload.remove)) {
      yield call(() => removeNotifications(payload.remove));
    }
    yield put(actions.editNotificationsSuccess());
  } catch (e) {
    yield put(actions.editNotificationsFail(e?.response?.data?.message || 'Network error'));
  }
}

function* deleteSaga({ payload }: { payload: { id: number } }) {
  try {
    yield call(() => deleteMe(payload.id));
    yield put(actions.deleteSuccess());
  } catch (e) {
    yield put(actions.deleteFail(e?.response?.data?.message || 'Network error'));
  }
}

// function* checkoutSaga({
// payload,
// }: {
// payload: { id: number; data: { buyerStatus: string; comment: string } };
// }) {
// try {
// const { data }: { data: IServerResponse<IProduct> } = yield call(() =>
// checkout(payload.id, payload.data)
// );
// yield put(actions.checkoutSuccess(data));
// } catch (e) {
// yield put(actions.checkoutFail(e?.response?.data?.message || 'Network error'));
// }
// }

function* uploadAvatarSaga({ payload }: { payload: FormData }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => uploadAvatar(payload));
    yield put(actions.uploadAvatarSuccess(data));
  } catch (e) {
    yield put(actions.uploadAvatarFail(e?.response?.data?.message || 'Network error'));
  }
}

function* uploadResumeSaga({ payload }: { payload: FormData }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => uploadResume(payload));
    yield put(actions.uploadResumeSuccess(data));
  } catch (e) {
    yield put(actions.uploadResumeFail(e?.response?.data?.message || 'Network error'));
  }
}

function* deleteResumeSaga() {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => deleteResume());
    yield put(actions.deleteResumeSuccess(data));
  } catch (e) {
    yield put(actions.deleteResumeFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchViewsSaga() {
  try {
    const { data }: { data: IServerResponse<IUserViews> } = yield call(() => getViews());
    yield put(actions.viewsSuccess(data));
  } catch (e) {
    yield put(actions.viewsFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.editNotificationsRequest>>(
    EDIT_NOTIFICATIONS_REQUEST,
    editNotificationsSaga
  );
  yield takeLatest<ReturnType<typeof actions.deleteRequest>>(DELETE_REQUEST, deleteSaga);
  // yield takeLatest<ReturnType<typeof actions.checkoutRequest>>(CHECKOUT_REQUEST, checkoutSaga);
  yield takeLatest<ReturnType<typeof actions.uploadAvatarRequest>>(
    UPLOAD_AVATAR_REQUEST,
    uploadAvatarSaga
  );
  yield takeLatest<ReturnType<typeof actions.uploadResumeRequest>>(
    UPLOAD_RESUME_REQUEST,
    uploadResumeSaga
  );
  yield takeLatest<ReturnType<typeof actions.deleteResumeRequest>>(
    DELETE_RESUME_REQUEST,
    deleteResumeSaga
  );
  yield takeLatest<ReturnType<typeof actions.viewsRequest>>(FETCH_VIEWS_REQUEST, fetchViewsSaga);
}
