import axios from 'axios';
import {
  ATTRIBUTE_COUNTRIES_URL,
  ATTRIBUTE_EDUCATION_URL,
  ATTRIBUTE_SKILLS_URL,
  ATTRIBUTE_LANGUAGES_URL,
} from '../constants';

export function getCountries() {
  return axios.get(`${ATTRIBUTE_COUNTRIES_URL}`);
}

export function getEducations() {
  return axios.get(`${ATTRIBUTE_EDUCATION_URL}`);
}

export function getSkills() {
  return axios.get(`${ATTRIBUTE_SKILLS_URL}`);
}

export function getLanguages() {
  return axios.get(`${ATTRIBUTE_LANGUAGES_URL}`);
}
