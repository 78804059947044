import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core';
import ButtonWithLoader from '../../components/ui/Buttons/ButtonWithLoader';
import { Link, useHistory } from 'react-router-dom';

import { toAbsoluteUrl } from '../../../_metronic';
import { IAppState } from '../../store/rootDuck';
import { actions as authActions } from '../../store/ducks/auth.duck';
import Topbar from '../../../_metronic/layout/header/LanguageTopbar.js';

const useStyles = makeStyles((theme) => ({
  languageBlock: {
    display: 'none',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  form: {
    background: '#fff',
    padding: '20px 40px',
    borderRadius: '5px',
  },
  headerBtn: {
    marginLeft: theme.spacing(1),
  },
}));

const Login: React.FC<WrappedComponentProps & TPropsFromRedux> = ({
  intl,
  user,
  authToken,
  clearLogin,
  login,
  loginLoading,
  loginSuccess,
  loginErrors,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (loginSuccess || loginErrors) {
      if (loginErrors === 'Логин/пароль указан неверно') {
        loginErrors = 'login/password is incorrect';
      }

      if (loginErrors) {
        enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${loginErrors}`, {
          variant: 'error',
        });
      }
    }
  }, [loginSuccess, loginErrors, enqueueSnackbar, clearLogin, intl]);

  useEffect(() => {
    if (loginSuccess) {
      clearLogin();
      history.replace('/');
    }
  }, [clearLogin, fetch, loginSuccess]);

  return (
    <>
      <div className="kt-login__head">
        <div className="kt-login__signup">
          <span className="kt-login__signup-label" style={{ color: '#fff' }}>
            {intl.formatMessage({ id: 'AUTH.GENERAL.NO_ACCOUNT' })}
          </span>
          &nbsp;&nbsp;
          <Button
            onClick={() => {
              history.push('/auth/registration');
            }}
            className={classes.headerBtn}
            variant="contained"
            color="secondary"
          >
            {intl.formatMessage({ id: 'AUTH.GENERAL.SIGNUP_BUTTON' })}
          </Button>
        </div>
        <div className={classes.languageBlock}>
          <Topbar />
        </div>
      </div>

      <div className="kt-login__body">
        <div className={`kt-login__form ${classes.form}`}>
          <div className="kt-login__title" style={{ marginTop: '1.5rem', marginBottom: '3rem' }}>
            <h3 style={{ fontWeight: 700, fontSize: '2.5rem', color: '#043655' }}>
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              login: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              login: Yup.string().required(intl.formatMessage({ id: 'AUTH.INPUT.LOGIN_REQUIRED' })),
              password: Yup.string().required(
                intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD_REQUIRED' })
              ),
            })}
            onSubmit={(values) => {
              login({ login: values.login, password: values.password });
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {/* <div role="alert" className="alert alert-info">
                  <div className="alert-text">
                    Use account <strong>admin</strong> and password <strong>000000</strong> to
                    continue.
                  </div>
                </div> */}
                <div className="form-group">
                  <TextField
                    type="email"
                    label={intl.formatMessage({
                      id: 'AUTH.INPUT.LOGIN',
                    })}
                    margin="normal"
                    className="kt-width-full"
                    name="login"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.login}
                    helperText={touched.login && errors.login}
                    error={Boolean(touched.login && errors.login)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    autoComplete="off"
                    type="password"
                    margin="normal"
                    label={intl.formatMessage({
                      id: 'AUTH.INPUT.PASSWORD',
                    })}
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className="kt-login__actions">
                  <ButtonWithLoader full disabled={loginLoading} loading={loginLoading}>
                    <span style={{ fontWeight: 600 }}>
                      {intl.formatMessage({ id: 'AUTH.LOGIN.BUTTON' })}
                    </span>
                  </ButtonWithLoader>
                </div>
              </form>
            )}
          </Formik>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              alignSelf: 'flex-end',
              marginBottom: '1.5rem',
              paddingTop: '1.5rem',
              textAlign: 'center',
            }}
          >
            <p>
              Don't have an account?{' '}
              <Link className="kt-link" to="/auth/registration">
                Sign up
              </Link>
            </p>

            <Link className="kt-link" to="/auth/forgot-password">
              {intl.formatMessage({ id: 'AUTH.LOGIN.FORGOT_PASSWORD' })}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    user: state.auth.user,
    authToken: state.auth.authToken,
    loginLoading: state.auth.loginLoading,
    loginSuccess: state.auth.loginSuccess,
    loginErrors: state.auth.loginErrors,
  }),
  {
    clearLogin: authActions.clearLogin,
    login: authActions.loginRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default injectIntl(connector(Login));
