import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  createCompany,
  delCompany,
  editCompany,
  getCompanies,
  getCompaniesViews,
  getCompany,
  searchCompanies,
  uploadCompanyLogo,
} from '../../crud/companies.crud';
import { ICompany, ICompanyAddProps, ICompanyEditProps } from '../../interfaces/companies';
import { IUserViews } from '../../interfaces/user';

const CLEAR_FETCH = 'companies/CLEAR_FETCH';
const FETCH_REQUEST = 'companies/FETCH_REQUEST';
const FETCH_SUCCESS = 'companies/FETCH_SUCCESS';
const FETCH_FAIL = 'companies/FETCH_FAIL';
const CLEAR_COMPANIES = 'companies/CLEAR_COMPANIES';

const CLEAR_FETCH_BY_ID = 'companies/CLEAR_FETCH_BY_ID';
const FETCH_BY_ID_REQUEST = 'companies/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'companies/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'companies/FETCH_BY_ID_FAIL';

const CLEAR_SEARCH = 'companies/CLEAR_SEARCH';
const SEARCH_REQUEST = 'companies/SEARCH_REQUEST';
const SEARCH_SUCCESS = 'companies/SEARCH_SUCCESS';
const SEARCH_FAIL = 'companies/SEARCH_FAIL';

const CLEAR_EDIT = 'companies/CLEAR_EDIT';
const ADD_REQUEST = 'companies/ADD_REQUEST';
const EDIT_REQUEST = 'companies/EDIT_REQUEST';
const EDIT_SUCCESS = 'companies/EDIT_SUCCESS';
const EDIT_FAIL = 'companies/EDIT_FAIL';

const CLEAR_DEL_COMPANY = 'companies/CLEAR_DEL_COMPANY';
const DEL_COMPANY_REQUEST = 'companies/DEL_COMPANY_REQUEST';
const DEL_COMPANY_SUCCESS = 'companies/DEL_COMPANY_SUCCESS';
const DEL_COMPANY_FAIL = 'companies/DEL_COMPANY_FAIL';

const UPLOAD_LOGO_REQUEST = 'companies/UPLOAD_LOGO_REQUEST';
const UPLOAD_LOGO_SUCCESS = 'companies/UPLOAD_LOGO_SUCCESS';
const UPLOAD_LOGO_FAIL = 'companies/UPLOAD_LOGO_FAIL';

const SET_FILTER = 'companies/SET_FILTER';
const CLEAR_FILTER = 'companies/CLEAR_FILTER';

const CLEAR_VIEWS = 'companies/CLEAR_VIEWS';
const FETCH_VIEWS_REQUEST = 'companies/FETCH_VIEWS_REQUEST';
const FETCH_VIEWS_SUCCESS = 'companies/FETCH_VIEWS_SUCCESS';
const FETCH_VIEWS_FAIL = 'companies/FETCH_VIEWS_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  companies: ICompany[];
  loading: boolean;
  success: boolean;
  error: string | null;

  company: ICompany | null;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  searchLoading: boolean;
  searchSuccess: boolean;
  searchError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  delCompanyLoading: boolean;
  delCompanySuccess: boolean;
  delCompanyError: string | null;

  uploadLogoLoading: boolean;
  uploadLogoSuccess: boolean;
  uploadLogoError: string | null;

  filter: {
    category_id: string;
    services: number[];
  };

  views: IUserViews | null;
  viewsLoading: boolean;
  viewsSuccess: boolean;
  viewsError: string | null;
}

const defaultPaginatorProps = { page: 1, per_page: 20, total: 0 };

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  companies: [],
  loading: false,
  success: false,
  error: null,

  company: null,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  searchLoading: false,
  searchSuccess: false,
  searchError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  delCompanyLoading: false,
  delCompanySuccess: false,
  delCompanyError: null,

  uploadLogoLoading: false,
  uploadLogoSuccess: false,
  uploadLogoError: null,

  filter: {
    category_id: 'all',
    services: [],
  },

  views: null,
  viewsLoading: false,
  viewsSuccess: false,
  viewsError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'companies', whitelist: ['companies', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_FILTER: {
        return {
          ...state,
          filter: {
            category_id: 'all',
            services: [],
          },
        };
      }

      case SET_FILTER: {
        return {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload,
          },
        };
      }

      case CLEAR_FETCH: {
        return { ...state, loading: false, error: null, success: false };
      }

      case CLEAR_COMPANIES: {
        return { ...state, ...defaultPaginatorProps, companies: [] };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          ...defaultPaginatorProps,
          companies: [],
          loading: true,
          error: null,
          success: false,
          page: action.payload.page,
          per_page: action.payload.perPage,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          companies: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case CLEAR_SEARCH: {
        return {
          ...state,
          companies: [],
          searchLoading: false,
          searchError: null,
          searchSuccess: false,
        };
      }

      case SEARCH_REQUEST: {
        return {
          ...state,
          companies: [],
          searchLoading: true,
          searchError: null,
          searchSuccess: false,
        };
      }

      case SEARCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          companies: action.payload.data,
          searchLoading: false,
          searchSuccess: true,
        };
      }

      case SEARCH_FAIL: {
        return { ...state, searchLoading: false, searchError: action.payload };
      }

      case CLEAR_FETCH_BY_ID: {
        return { ...state, company: null, byIdLoading: false, byIdError: null, byIdSuccess: false };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          company: null,
          byIdLoading: true,
          byIdError: null,
          byIdSuccess: false,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return { ...state, company: action.payload.data, byIdLoading: false, byIdSuccess: true };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          editLoading: false,
          editError: null,
          editSuccess: false,
          company: null,
        };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editError: null, editSuccess: false };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editError: null, editSuccess: false };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true, company: action.payload };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case CLEAR_DEL_COMPANY: {
        return {
          ...state,
          delCompanyLoading: false,
          delCompanyError: null,
          delCompanySuccess: false,
        };
      }

      case DEL_COMPANY_REQUEST: {
        return {
          ...state,
          delCompanyLoading: true,
          delCompanyError: null,
          delCompanySuccess: false,
        };
      }

      case DEL_COMPANY_SUCCESS: {
        return { ...state, delCompanyLoading: false, delCompanySuccess: true };
      }

      case DEL_COMPANY_FAIL: {
        return { ...state, delCompanyLoading: false, delCompanyError: action.payload };
      }

      case UPLOAD_LOGO_REQUEST: {
        return {
          ...state,
          uploadLogoLoading: true,
          uploadLogoError: null,
          uploadLogoSuccess: false,
        };
      }

      case UPLOAD_LOGO_SUCCESS: {
        return {
          ...state,
          company: action.payload.data,
          uploadLogoLoading: false,
          uploadLogoError: null,
          uploadLogoSuccess: true,
        };
      }

      case UPLOAD_LOGO_FAIL: {
        return {
          ...state,
          uploadLogoLoading: false,
          uploadLogoError: action.payload,
          uploadLogoSuccess: false,
        };
      }

      case CLEAR_VIEWS: {
        return {
          ...state,
          viewsLoading: false,
          viewsError: null,
          viewsSuccess: false,
          views: null,
        };
      }

      case FETCH_VIEWS_REQUEST: {
        return { ...state, viewsLoading: true, viewsError: null, viewsSuccess: false };
      }

      case FETCH_VIEWS_SUCCESS: {
        return { ...state, viewsLoading: false, viewsSuccess: true, views: action.payload.data };
      }

      case FETCH_VIEWS_FAIL: {
        return { ...state, viewsLoading: false, viewsError: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearFetch: () => createAction(CLEAR_FETCH),
  fetchRequest: (payload: { page: number; perPage: number; filter?: { category_id?: string } }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<ICompany[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),
  clearCompanies: () => createAction(CLEAR_COMPANIES),

  clearFetchById: () => createAction(CLEAR_FETCH_BY_ID),
  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<ICompany>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearSearch: () => createAction(CLEAR_SEARCH),
  searchRequest: (payload: number) => createAction(SEARCH_REQUEST, payload),
  searchSuccess: (payload: IServerResponse<ICompany[]>) => createAction(SEARCH_SUCCESS, payload),
  searchFail: (payload: string) => createAction(SEARCH_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: ICompanyAddProps) => createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: ICompanyEditProps }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: (data: ICompany) => createAction(EDIT_SUCCESS, data),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  clearDelCompany: () => createAction(CLEAR_DEL_COMPANY),
  delCompanyRequest: (payload: number) => createAction(DEL_COMPANY_REQUEST, payload),
  delCompanySuccess: () => createAction(DEL_COMPANY_SUCCESS),
  delCompanyFail: (payload: string) => createAction(DEL_COMPANY_FAIL, payload),

  uploadLogoRequest: (payload: { photo: FormData; id: string }) =>
    createAction(UPLOAD_LOGO_REQUEST, payload),
  uploadLogoSuccess: (payload: IServerResponse<ICompany>) =>
    createAction(UPLOAD_LOGO_SUCCESS, payload),
  uploadLogoFail: (payload: string) => createAction(UPLOAD_LOGO_FAIL, payload),

  clearFilter: () => createAction(CLEAR_FILTER),

  setFilter: (payload: { category_id?: string; services?: number[] }) =>
    createAction(SET_FILTER, payload),

  clearViews: () => createAction(CLEAR_VIEWS),
  viewsRequest: () => createAction(FETCH_VIEWS_REQUEST),
  viewsSuccess: (payload: IServerResponse<IUserViews>) =>
    createAction(FETCH_VIEWS_SUCCESS, payload),
  viewsFail: (payload: string) => createAction(FETCH_VIEWS_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: { page: number; perPage: number; filter?: { category_id?: string } };
}) {
  try {
    const { data }: { data: IServerResponse<ICompany[]> } = yield call(() =>
      getCompanies(payload.page, payload.perPage, payload.filter || {})
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<ICompany> } = yield call(() => getCompany(payload));
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(e?.response?.data?.message || 'Network error'));
  }
}

function* searchSaga({ payload }: { payload: number }) {
  try {
    yield put(actions.clearFetch());
    const { data }: { data: IServerResponse<ICompany[]> } = yield call(() =>
      searchCompanies(payload)
    );
    yield put(actions.searchSuccess(data));
  } catch (e) {
    yield put(actions.searchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* addSaga({ payload }: { payload: ICompanyAddProps }) {
  try {
    const { data }: { data: IServerResponse<ICompany> } = yield call(() => createCompany(payload));
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: ICompanyEditProps } }) {
  try {
    const { data }: { data: IServerResponse<ICompany> } = yield call(() =>
      editCompany(payload.id, payload.data)
    );
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* delCompanySaga({ payload }: { payload: number }) {
  try {
    yield call(() => delCompany(payload));
    yield put(actions.delCompanySuccess());
  } catch (e) {
    yield put(actions.delCompanyFail(e?.response?.data?.message || 'Network error'));
  }
}

function* uploadLogoSaga({ payload }: { payload: { photo: FormData; id: string } }) {
  try {
    const { data }: { data: IServerResponse<ICompany> } = yield call(() =>
      uploadCompanyLogo(payload)
    );
    yield put(actions.uploadLogoSuccess(data));
  } catch (e) {
    yield put(actions.uploadLogoFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchViewsSaga() {
  try {
    const { data }: { data: IServerResponse<IUserViews> } = yield call(() => getCompaniesViews());
    yield put(actions.viewsSuccess(data));
  } catch (e) {
    yield put(actions.viewsFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
  yield takeLatest<ReturnType<typeof actions.searchRequest>>(SEARCH_REQUEST, searchSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.delCompanyRequest>>(
    DEL_COMPANY_REQUEST,
    delCompanySaga
  );
  yield takeLatest<ReturnType<typeof actions.uploadLogoRequest>>(
    UPLOAD_LOGO_REQUEST,
    uploadLogoSaga
  );
  yield takeLatest<ReturnType<typeof actions.viewsRequest>>(FETCH_VIEWS_REQUEST, fetchViewsSaga);
}
