import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { put, takeLatest, call } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  IGenerationItem,
  IGenerationItemToRequest,
} from '../../pages/home/catalog/generations/interfaces';
import {
  getGenerationById,
  getGeneration,
  addGeneration,
  editGeneration,
} from '../../crud/generations.crud';

const FETCH_REQUEST = 'generations/FETCH_REQUEST';
const FETCH_SUCCESS = 'generations/FETCH_SUCCESS';
const FETCH_FAIL = 'generations/FETCH_FAIL';

const FETCH_BY_ID_REQUEST = 'generations/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'generations/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'generations/FETCH_BY_ID_FAIL';

const CLEAR_EDIT = 'generations/CLEAR_EDIT';
const ADD_REQUEST = 'generations/ADD_REQUEST';
const EDIT_REQUEST = 'generations/EDIT_REQUEST';
const EDIT_SUCCESS = 'generations/EDIT_SUCCESS';
const EDIT_FAIL = 'generations/EDIT_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  generations: IGenerationItem[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  generation: IGenerationItem | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: any;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  generations: undefined,
  loading: false,
  success: false,
  error: null,

  generation: undefined,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'generations', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REQUEST: {
        return {
          ...state,
          generations: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          generations: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          generation: undefined,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          generation: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return { ...state, editLoading: false, editSuccess: false, editError: null };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: { page: number; perPage: number; id: number }) =>
    createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IGenerationItem[]>) =>
    createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IGenerationItem>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: { modelId: number; data: IGenerationItemToRequest }) =>
    createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: IGenerationItemToRequest }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: () => createAction(EDIT_SUCCESS),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({ payload }: { payload: { page: number; perPage: number; id: number } }) {
  try {
    const { data }: { data: IServerResponse<IGenerationItem[]> } = yield call(() =>
      getGeneration(payload.page, payload.perPage, payload.id)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IGenerationItem> } = yield call(() =>
      getGenerationById(payload)
    );
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(e?.response?.data?.message || 'Network error'));
  }
}

function* addSaga({ payload }: { payload: { modelId: number; data: IGenerationItemToRequest } }) {
  try {
    yield call(() => addGeneration(payload.modelId, payload.data));
    yield put(actions.editSuccess());
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: IGenerationItemToRequest } }) {
  try {
    yield call(() => editGeneration(payload.id, payload.data));
    yield put(actions.editSuccess());
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
}
