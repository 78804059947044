import { IUser, TRole } from '../pages/auth/interfaces';
import { IntlShape } from 'react-intl';

export const accessByRoles = (user: IUser | undefined, roles: TRole[]): boolean => {
  if (!user) {
    return false;
  } else {
    if (user?.roles?.length && roles.includes(user.roles[0])) {
      return true;
    } else {
      return false;
    }
  }
};

export const getConfirmCompanyString = (user: IUser | undefined, intl: IntlShape): string => {
  let confirmCompanyString = '';
  // if (!user?.company) {
  //   confirmCompanyString = intl.formatMessage({ id: 'COMPANY.NO_COMPANY' });
  // } else {
  //   if (!user.company_confirmed_by_email && !user.company_confirmed_by_payment) {
  //     confirmCompanyString = intl.formatMessage({ id: 'COMPANY.CONFIRM.NO_CONFIRM' });
  //   } else {
  //     confirmCompanyString = intl.formatMessage({ id: 'COMPANY.CONFIRM.TITLE2' });
  //   }
  // }
  return confirmCompanyString;
};

export const getFullname = (user?: IUser | null): string => {
  return user && user.first_name && user.last_name
    ? `${user.first_name} ${user.last_name}`
    : 'No name';
};
