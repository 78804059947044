import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { put, takeLatest, call } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  IPropertyItem,
  IPropertyItemToEdit,
  IPropertyItemToAdd,
  TVehicleProperty,
} from '../../pages/home/properties/interfaces';
import { getProperties, addProperty, editProperty } from '../../crud/properties.crud';

const FETCH_REQUEST = 'pcountries/FETCH_REQUEST';
const FETCH_SUCCESS = 'pcountries/FETCH_SUCCESS';
const FETCH_FAIL = 'pcountries/FETCH_FAIL';

const CLEAR_EDIT = 'pcountries/CLEAR_EDIT';
const ADD_REQUEST = 'pcountries/ADD_REQUEST';
const EDIT_REQUEST = 'pcountries/EDIT_REQUEST';
const EDIT_SUCCESS = 'pcountries/EDIT_SUCCESS';
const EDIT_FAIL = 'pcountries/EDIT_FAIL';

export interface IInitialState {
  pcountries: IPropertyItem[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;
}

const initialState: IInitialState = {
  pcountries: undefined,
  loading: false,
  success: false,
  error: null,

  editLoading: false,
  editSuccess: false,
  editError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'pcountries', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REQUEST: {
        return {
          ...state,
          pcountries: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        return { ...state, pcountries: action.payload.data, loading: false, success: true };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case CLEAR_EDIT: {
        return { ...state, editLoading: false, editSuccess: false, editError: null };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: TVehicleProperty) => createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IPropertyItem[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: { type: TVehicleProperty; data: IPropertyItemToAdd }) =>
    createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: IPropertyItemToEdit }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: () => createAction(EDIT_SUCCESS),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({ payload }: { payload: TVehicleProperty }) {
  try {
    const { data }: { data: IServerResponse<IPropertyItem[]> } = yield call(() =>
      getProperties(payload)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {}
}

function* addSaga({ payload }: { payload: { type: TVehicleProperty; data: IPropertyItemToAdd } }) {
  try {
    yield call(() => addProperty(payload.type, payload.data));
    yield put(actions.editSuccess());
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: IPropertyItemToEdit } }) {
  try {
    yield call(() => editProperty(payload.id, payload.data));
    yield put(actions.editSuccess());
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
}
