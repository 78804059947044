import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  createUser,
  delUser,
  editUser,
  getUser,
  getUsersByRole,
  sendInvite,
  uploadUserAvatar,
  getUsersCsv,
} from '../../crud/users.crud';
import { IUser, IUserAddProps, IUserEditProps } from '../../interfaces/user';

const CLEAR_FETCH = 'users/CLEAR_FETCH';
const FETCH_REQUEST = 'users/FETCH_REQUEST';
const FETCH_SUCCESS = 'users/FETCH_SUCCESS';
const FETCH_FAIL = 'users/FETCH_FAIL';

const CLEAR_FETCH_BY_ID = 'users/CLEAR_FETCH_BY_ID';
const FETCH_BY_ID_REQUEST = 'users/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'users/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'users/FETCH_BY_ID_FAIL';

const CLEAR_EDIT = 'users/CLEAR_EDIT';
const ADD_REQUEST = 'users/ADD_REQUEST';
const EDIT_REQUEST = 'users/EDIT_REQUEST';
const EDIT_SUCCESS = 'users/EDIT_SUCCESS';
const EDIT_FAIL = 'users/EDIT_FAIL';

const CLEAR_SEND_INVITE = 'users/CLEAR_SEND_INVITE';
const SEND_INVITE_REQUEST = 'users/SEND_INVITE_REQUEST';
const SEND_INVITE_SUCCESS = 'users/SEND_INVITE_SUCCESS';
const SEND_INVITE_FAIL = 'users/SEND_INVITE_FAIL';

const CLEAR_DEL_USER = 'users/CLEAR_DEL_USER';
const DEL_USER_REQUEST = 'users/DEL_USER_REQUEST';
const DEL_USER_SUCCESS = 'users/DEL_USER_SUCCESS';
const DEL_USER_FAIL = 'users/DEL_USER_FAIL';

const UPLOAD_AVATAR_REQUEST = 'users/UPLOAD_AVATAR_REQUEST';
const UPLOAD_AVATAR_SUCCESS = 'users/UPLOAD_AVATAR_SUCCESS';
const UPLOAD_AVATAR_FAIL = 'users/UPLOAD_AVATAR_FAIL';

const CLEAR_FILTER = 'users/CLEAR_FILTER';
const SET_FILTER = 'users/SET_FILTER';

const CLEAR_USERS_CSV = 'users/CLEAR_USERS_CSV';
const USERS_CSV_REQUEST = 'users/USERS_CSV_REQUEST';
const USERS_CSV_SUCCESS = 'USERS_CSV_SUCCESS';
const USERS_CSV_FAIL = 'USERS_CSV_FAIL';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  users: IUser[];
  loading: boolean;
  success: boolean;
  error: string | null;

  user: IUser | null;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  sendInviteLoading: boolean;
  sendInviteSuccess: boolean;
  sendInviteError: string | null;

  delUserLoading: boolean;
  delUserSuccess: boolean;
  delUserError: string | null;

  uploadAvatarLoading: boolean;
  uploadAvatarSuccess: boolean;
  uploadAvatarError: string | null;

  filter: {
    ediscoveries: string[];
    education_degree: string[];
    languages: string[];
    country_qualification: string[];
    available_for_work: boolean;
    category_id: string;
  };

  usersCsvBlob: any | undefined;
  usersCsvLoading: boolean;
  usersCsvSuccess: boolean;
  usersCsvError: string | null;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  users: [],
  loading: false,
  success: false,
  error: null,

  user: null,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  sendInviteLoading: false,
  sendInviteSuccess: false,
  sendInviteError: null,

  delUserLoading: false,
  delUserSuccess: false,
  delUserError: null,

  uploadAvatarLoading: false,
  uploadAvatarSuccess: false,
  uploadAvatarError: null,

  filter: {
    ediscoveries: [],
    education_degree: [],
    languages: [],
    country_qualification: [],
    available_for_work: false,
    category_id: 'all',
  },

  usersCsvBlob: undefined,
  usersCsvLoading: false,
  usersCsvSuccess: false,
  usersCsvError: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'users', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_FILTER: {
        return {
          ...state,
          filter: {
            ediscoveries: [],
            education_degree: [],
            languages: [],
            country_qualification: [],
            available_for_work: false,
            category_id: 'all',
          },
        };
      }

      case SET_FILTER: {
        return {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload,
          },
        };
      }

      case CLEAR_FETCH: {
        return { ...state, loading: false, error: null, success: false, users: [] };
      }

      case FETCH_REQUEST: {
        return {
          ...state,
          users: [],
          loading: true,
          error: null,
          success: false,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          users: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case CLEAR_FETCH_BY_ID: {
        return { ...state, user: null, byIdLoading: false, byIdError: null, byIdSuccess: false };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          user: null,
          byIdLoading: true,
          byIdError: null,
          byIdSuccess: false,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return { ...state, user: action.payload.data, byIdLoading: false, byIdSuccess: true };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case CLEAR_EDIT: {
        return {
          ...state,
          editLoading: false,
          editError: null,
          editSuccess: false,
          user: null,
        };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editError: null, editSuccess: false };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editError: null, editSuccess: false };
      }

      case EDIT_SUCCESS: {
        return { ...state, editLoading: false, editSuccess: true, user: action.payload };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case CLEAR_SEND_INVITE: {
        return {
          ...state,
          sendInviteLoading: false,
          sendInviteError: null,
          sendInviteSuccess: false,
        };
      }

      case SEND_INVITE_REQUEST: {
        return {
          ...state,
          sendInviteLoading: true,
          sendInviteError: null,
          sendInviteSuccess: false,
        };
      }

      case SEND_INVITE_SUCCESS: {
        return { ...state, sendInviteLoading: false, sendInviteSuccess: true };
      }

      case SEND_INVITE_FAIL: {
        return { ...state, sendInviteLoading: false, sendInviteError: action.payload };
      }

      case CLEAR_DEL_USER: {
        return { ...state, delUserLoading: false, delUserError: null, delUserSuccess: false };
      }

      case DEL_USER_REQUEST: {
        return { ...state, delUserLoading: true, delUserError: null, delUserSuccess: false };
      }

      case DEL_USER_SUCCESS: {
        return { ...state, delUserLoading: false, delUserSuccess: true };
      }

      case DEL_USER_FAIL: {
        return { ...state, delUserLoading: false, delUserError: action.payload };
      }

      case UPLOAD_AVATAR_REQUEST: {
        return {
          ...state,
          uploadAvatarLoading: true,
          uploadAvatarError: null,
          uploadAvatarSuccess: false,
        };
      }

      case UPLOAD_AVATAR_SUCCESS: {
        return {
          ...state,
          user: action.payload.data,
          uploadAvatarLoading: false,
          uploadAvatarError: null,
          uploadAvatarSuccess: true,
        };
      }

      case UPLOAD_AVATAR_FAIL: {
        return {
          ...state,
          uploadAvatarLoading: false,
          uploadAvatarError: action.payload,
          uploadAvatarSuccess: false,
        };
      }

      case CLEAR_USERS_CSV: {
        return {
          ...state,
          usersCsvLoading: false,
          usersCsvSuccess: false,
          usersCsvError: null
        };
      }

      case USERS_CSV_REQUEST: {
        return {
          ...state,
          usersCsvLoading: true,
          usersCsvSuccess: false,
          usersCsvError: null
        };
      }

      case USERS_CSV_SUCCESS: {
        return {
          ...state,
          usersCsvBlob: action.payload,
          usersCsvLoading: false,
          usersCsvSuccess: true,
        };
      }

      case USERS_CSV_FAIL: {
        return {
          ...state,
          usersCsvLoading: false,
          usersCsvError: action.payload,
        }
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearFetch: () => createAction(CLEAR_FETCH),
  fetchRequest: (payload: {
    page: number;
    perPage: number;
    role: string;
    filter?: {
      ediscoveries: string[];
      education_degree: string[];
      languages: string[];
      country_qualification: string[];
      available_for_work: boolean;
      category_id: string;
    };
  }) => createAction(FETCH_REQUEST, payload),
  fetchSuccess: (payload: IServerResponse<IUser[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  clearFetchById: () => createAction(CLEAR_FETCH_BY_ID),
  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IUser>) => createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: IUserAddProps) => createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: IUserEditProps }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: (data: IUser) => createAction(EDIT_SUCCESS, data),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  clearSendInvite: () => createAction(CLEAR_SEND_INVITE),
  sendInviteRequest: (payload: string) => createAction(SEND_INVITE_REQUEST, payload),
  sendInviteSuccess: () => createAction(SEND_INVITE_SUCCESS),
  sendInviteFail: (payload: string) => createAction(SEND_INVITE_FAIL, payload),

  clearDelUser: () => createAction(CLEAR_DEL_USER),
  delUserRequest: (payload: number) => createAction(DEL_USER_REQUEST, payload),
  delUserSuccess: () => createAction(DEL_USER_SUCCESS),
  delUserFail: (payload: string) => createAction(DEL_USER_FAIL, payload),

  uploadAvatarRequest: (payload: { photo: FormData; id: string }) =>
    createAction(UPLOAD_AVATAR_REQUEST, payload),
  uploadAvatarSuccess: (payload: IServerResponse<IUser>) =>
    createAction(UPLOAD_AVATAR_SUCCESS, payload),
  uploadAvatarFail: (payload: string) => createAction(UPLOAD_AVATAR_FAIL, payload),

  clearFilter: () => createAction(CLEAR_FILTER),

  setFilter: (payload: {
    ediscoveries?: string[];
    education_degree?: string[];
    languages?: string[];
    country_qualification?: string[];
    available_for_work?: boolean;
    category_id?: string;
  }) => createAction(SET_FILTER, payload),

  clearUsersCsv: () => createAction(CLEAR_USERS_CSV),
  usersCsvRequest: (payload: string) => createAction(USERS_CSV_REQUEST, payload),
  usersCsvSuccess: (payload: IServerResponse<string>) => createAction(USERS_CSV_SUCCESS, payload),
  usersCsvFail: (payload: string) => createAction(USERS_CSV_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({
  payload,
}: {
  payload: {
    page: number;
    perPage: number;
    role: string;
    filter?: {
      ediscoveries: string[];
      education_degree: string[];
      languages: string[];
      country_qualification: string[];
      available_for_work: boolean;
      category_id: string;
    };
  };
}) {
  try {
    const { data }: { data: IServerResponse<IUser[]> } = yield call(() =>
      getUsersByRole(payload.page, payload.perPage, payload.role, payload.filter)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => getUser(payload));
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(e?.response?.data?.message || 'Network error'));
  }
}

function* addSaga({ payload }: { payload: IUserAddProps }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => createUser(payload));
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: IUserEditProps } }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() =>
      editUser(payload.id, payload.data)
    );
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(e?.response?.data?.message || 'Network error'));
  }
}

function* sendInviteSaga({ payload }: { payload: string }) {
  try {
    yield call(() => sendInvite(payload));
    yield put(actions.sendInviteSuccess());
  } catch (e) {
    yield put(actions.sendInviteFail(e?.response?.data?.message || 'Network error'));
  }
}

function* delUserSaga({ payload }: { payload: number }) {
  try {
    yield call(() => delUser(payload));
    yield put(actions.delUserSuccess());
  } catch (e) {
    yield put(actions.delUserFail(e?.response?.data?.message || 'Network error'));
  }
}

function* uploadAvatarSaga({ payload }: { payload: { photo: FormData; id: string } }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => uploadUserAvatar(payload));
    yield put(actions.uploadAvatarSuccess(data));
  } catch (e) {
    yield put(actions.uploadAvatarFail(e?.response?.data?.message || 'Network error'));
  }
}

function* usersCsvSaga({ payload }: { payload: string }) {
  try {
    const { data }: { data: IServerResponse<string> } = yield call(() => getUsersCsv(payload));
    yield put(actions.usersCsvSuccess(data));
  } catch (e) {
    yield put(actions.usersCsvFail(e?.response?.data?.message || 'Network error'))
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(FETCH_BY_ID_REQUEST, fetchByIdSaga);
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.sendInviteRequest>>(
    SEND_INVITE_REQUEST,
    sendInviteSaga
  );
  yield takeLatest<ReturnType<typeof actions.delUserRequest>>(DEL_USER_REQUEST, delUserSaga);
  yield takeLatest<ReturnType<typeof actions.uploadAvatarRequest>>(
    UPLOAD_AVATAR_REQUEST,
    uploadAvatarSaga
  );
  yield takeLatest<ReturnType<typeof actions.usersCsvRequest>>(USERS_CSV_REQUEST, usersCsvSaga);
}
