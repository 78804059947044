import React, { useState, useCallback } from 'react';
import { Link, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';

import { toAbsoluteUrl } from '../../../_metronic';
import '../../../_metronic/_assets/sass/pages/login/login-1.scss';
import Login from './Login';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordEmail from './ForgotPasswordEmail';
import NewPassword from './NewPassword';
import TermDialog from '../../components/ui/Dialog/TermDialog';
// import useFetch from '../../hooks/useFetch';
import EmailSentPage from './EmailSentPage';
import betaText from '../../utils/betaText';
import { Button } from '@material-ui/core';

const ModelsPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContentType, setDialogContentType] = useState('');

  // const [{ response }, requestUserAgreement] = useFetch("api/_public/document/user_agreement");

  // useEffect(() => {
  // requestUserAgreement();
  // }, [requestUserAgreement]);

  const handleDialogOpen = useCallback(
    (contentType) => {
      setDialogContentType(contentType);
      setOpenDialog(true);
    },
    [setDialogContentType, setOpenDialog]
  );

  return (
    <>
      <TermDialog
        isOpen={openDialog}
        handleClose={() => setOpenDialog(false)}
        contentType={dialogContentType}
      />
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div id="kt_login" className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
          <div
            style={{
              background: 'linear-gradient(180deg, rgba(6,114,181,1) 0%, rgba(4,43,99,1) 100%)',
            }}
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile"
          >
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                flex: '0.5 0.5 auto',
              }}
            >
              <div
                className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver"
                style={{ justifyContent: 'center' }}
              >
                <div
                  className="kt-grid__item kt-grid__item--middle"
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div className="kt-login__title">
                    <img
                      alt="Logo"
                      style={{ height: 150, width: 'auto' }}
                      src={toAbsoluteUrl('/media/logos/logoOnDarkNoSpacing.png')}
                    />
                  </div>
                  {/* <h3 className="kt-login__title">z
                    {intl.formatMessage({ id: 'AUTH.WELCOME.TITLE' })}
                  </h3> */}
                  <h4
                    className="kt-login__subtitle"
                    style={{ lineHeight: '30px', color: '#fff', marginBottom: 0, maxWidth: 300 }}
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: 'AUTH.WELCOME.SUBTITLE' }),
                    }}
                  />

                  {betaText}

                  <Button
                    size={'large'}
                    variant={'outlined'}
                    onClick={() => history.push('/')}
                    color={'secondary'}
                    style={{ marginTop: 45 }}
                  >
                    News Feed
                  </Button>
                </div>
              </div>

              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    <div className="kt-login__menu">
                      <a
                        href={'https://start-mobile.net'}
                        className="kt-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {intl.formatMessage({ id: 'AUTH.CREATOR.COMPANY' })}
                      </a>
                    </div>
                  </div>
                  <div className="kt-login__menu">
                    <a
                      className="kt-link"
                      onClick={() => handleDialogOpen('user_agreement')}
                      style={{ marginRight: 0 }}
                    >
                      <FormattedMessage id="SUBMENU.LEGAL_TERMS" />
                    </a>
                    <span
                      style={{ color: 'rgba(255, 255, 255, 0.7)', marginRight: 4, marginLeft: 4 }}
                    >
                      |
                    </span>
                    <a className="kt-link" onClick={() => handleDialogOpen('privacy_policy')}>
                      <FormattedMessage id="SUBMENU.LEGAL_PRIVACY" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
              style={{ background: 'transparent', minWidth: '50%' }}
            >
              <Switch>
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/registration" component={Registration} />
                <Route path="/auth/email-sent/registration" component={EmailSentPage} />
                <Route path="/auth/forgot-password" component={ForgotPassword} />
                <Route path="/auth/forgot-password-email" component={ForgotPasswordEmail} />
                <Route path="/auth/change_password/:code" component={NewPassword} />
                <Redirect from="/auth" exact to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModelsPage;
