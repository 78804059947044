export enum userListType {
  BUYER = 'buyers',
  MANAGER = 'managers',
  VENDOR = 'vendors',
}

export const admin_role = 'ROLE_ADMIN';
export const buyer_role = 'ROLE_BUYER';
export const manager_role = 'ROLE_ADMIN,ROLE_MANAGER';
export const vendor_role = 'ROLE_VENDOR';
export const all_role = 'ROLE_ADMIN,ROLE_MANAGER,ROLE_VENDOR,ROLE_USER';
