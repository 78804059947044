import { TVehicleProperty } from '../pages/home/properties/interfaces';
import { ICreateSubscription, IGetStripeUser } from '../interfaces/tariff';

const {
  REACT_APP_API_DOMAIN = 'law-api.me-interactive.net',
  REACT_APP_GOOGLE_API_KEY = 'c1170a31-17b7-4be1-8db7-e068998ee7cd',
} = process.env;

const { protocol } = window.location;
const API_DOMAIN = `${protocol}//${REACT_APP_API_DOMAIN}`;

const API_SEARCH_LOCATION_URI = `https://geocode-maps.yandex.ru/1.x/?apikey=${REACT_APP_GOOGLE_API_KEY}`;

export { API_DOMAIN, API_SEARCH_LOCATION_URI };

export const LOGIN_URL = 'api/user/login';
export const REGISTER_URL = 'api/user/register';
export const getRegisterURL = (status: boolean): string =>
  `api/user/register?notifications=${status}`;
export const ME_URL = 'api/me';
export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';
export const getRecoveryPasswordUrl = (email: string): string =>
  `/api/_p/reset_password/send_code?email=${email}`;
export const NEW_PASSWORD_URL = '/api/_p/change_password_from_link';

export const GET_USERS_URL = '/api/users/filter';
export const CREATE_USER_URL = '/api/user/create';
export const getUserUrl = (id: number): string => `/api/user/${id}`;
export const editUserUrl = (id: number): string => `/api/user/${id}/edit`;
export const SEND_INVITE_URL = '/api/user_activate/send';
export const delUserUrl = (id: number): string => `/api/user/${id}`;
export const uploadUserAvatarUrl = (id: string): string => `/api/user/${id}/upload_photos`;
export const uploadMeResumeUrl = (): string => `/api/me/upload_resume`;
export const getViewsUrl = (): string => `/api/account/views`;
export const GET_USERS_CSV = '/api/users/csv';

export const GET_COMPANIES_URL = '/api/companies';
export const CREATE_COMPANY_URL = '/api/company';
export const SEARCH_COMPANY_URL = '/api/companies/search';
export const getCompanyUrl = (id: number): string => `/api/company/${id}`;
export const editCompanyUrl = (id: number, services: number[] = []): string => {
  let url = `/api/company/${id}`;

  if (services.length) {
    url += `?services=${services.join(',')}`;
  }

  return url;
};
export const delCompanyUrl = (id: number): string => `/api/company/${id}`;
export const uploadCompanyLogoUrl = (id: string): string => `/api/company/${id}/upload_logo`;
export const getCompanyViewsUrl = (): string => `/api/companies/views`;

export const GET_ACTIVE_BRANDS_URL = 'api/active_brands';
export const GET_BRANDS_URL = 'api/brands';
export const GET_BRAND_URL = 'api/brand';

export const getModelsUrl: (id: number) => string = (id) => `api/brand/${id}/models`;
export const getModelByIdUrl: (id: number) => string = (id) => `api/model/${id}`;
export const addModelUrl: (id: number) => string = (id) => `api/brand/${id}/model`;
export const editModelUrl: (id: number) => string = (id) => `api/brand/model/${id}`;

export const getGenerationsUrl: (id: number) => string = (id) => `api/model/${id}/generations`;
export const getGenerationByIdUrl: (id: number) => string = (id) => `api/generation/${id}`;
export const addGenerationUrl: (id: number) => string = (id) => `api/model/${id}/generation`;
export const editGenerationUrl: (id: number) => string = (id) => `api/generation/${id}`;

export const getBodiesUrl: (id: number) => string = (id) =>
  `api/generation/${id}/body_configurations`;
export const getBodyByIdUrl: (id: number) => string = (id) => `api/body_configuration/${id}`;
export const addBodyUrl: (id: number) => string = (id) => `api/generation/${id}/body_generation`;
export const editBodyUrl: (id: number) => string = (id) => `api/body_configuration/${id}`;

export const getModificationsUrl: (id: number) => string = (id) =>
  `/api/body_configuration/${id}/modifications`;
export const getModificationByIdUrl: (id: number) => string = (id) => `/api/modification/${id}`;
export const addModificationUrl: (id: number) => string = (id) =>
  `/api/body_generation/${id}/modification`;
export const editModificationUrl: (id: number) => string = (id) => `/api/modification/${id}`;

export const uploadPhotoUrl = (modificationId: number): string =>
  `/api/modification/${modificationId}/upload_photos`;
export const delPhotoUrl = (id: number): string => `/api/photo/${id}`;
export const setMainPhotoUrl = (id: number): string => `/api/photo/${id}/set_main`;

export const getEquipmentsUrl = (modificationId: number): string =>
  `/api/modification/${modificationId}/complectations`;
export const getEquipmentByIdUrl = (id: number): string => `/api/complectation/${id}`;
export const addEquipmentUrl = (modificationId: number): string =>
  `/api/modification/${modificationId}/complectation`;
export const editEquipmentUrl = (id: number): string => `/api/complectation/${id}`;

export const getPropertiesUrl = (type: TVehicleProperty): string => `api/properties/${type}`;
export const addPropertyUrl = (type: TVehicleProperty): string => `api/property/${type}/`;
export const editPropertyUrl = (id: number): string => `api/property/${id}`;

export const getAdvantageUrl = (): string => `/api/advantage`;
export const editAdvantageUrl = (): string => `/api/advantage/edit`;

export const GET_OPTIONS_GROUPS_URL = '/api/equpment_groups/full';

export const CREATE_CATEGORY_URL = '/api/shop/category';
export const getCategoryUrl = (id: number | undefined) => `/api/shop/category/${id}`;
export const FULL_CATEGORIES_URL = '/api/shop/categories/full';
export const ROOT_CATEGORIES_URL = '/api/shop/categories/roots';
export const getCategoriesWithChildrenUrl = (id: number) => `/api/shop/category/${id}/children`;
export const getCategoriesWithParentUrl = (id: number) => `/api/shop/category/${id}/parents`;
export const getCategoriesPositionsUrl = (positions: string) =>
  `/api/shop/categories/set_positions?positions=${positions}`;

export const PRODUCT_URL = '/api/shop/product';
export const PRODUCTS_URL = '/api/shop/products';
export const getProductByIdUrl = (id: number) => `/api/shop/product/${id}`;
export const getProductsByCategory = (id: number) => `/api/shop/category/${id}/products`;
export const delPhotoFromProduct = (id: number) => `/api/shop/product_photo/${id}`;
export const setProductMainPhotoUrl = (id: number): string =>
  `/api/shop/product_photo/${id}/set_main`;

export const PRODUCT_TYPES_URL = '/api/shop/product_types';
export const getProductTypeByIdUrl = (id: number) => `/api/shop/product_type/${id}`;
export const PRODUCT_TYPE_URL = '/api/shop/product_type';
export const delProdyctTypeByIdUrl = (id: number) => `/api/shop/product_type/${id}`;

export const addParameterForType = (id: number) => `/api/shop/product_type/${id}/parameter`;
export const editParameterForType = (id: number) => `/api/shop/pproduct_type_parameter/${id}`;
export const getParametersForType = (id: number) => `/api/shop/product_type/${id}/parameters`;

export const getOrdersByStatus = (statuses: string) => `/api/shop/orders?statuses=${statuses}`;
export const getOrderurl = (id: number) => `/api/shop/order/${id}`;

export const STORES_URL = '/api/shop/stores';
export const CREATE_STORE_URL = '/api/shop/store';
export const getStoreByIdUrl = (id: number) => `api/shop/store/${id}`;

export const TARIFFS_URL = '/api/tariffs';
export const getTariffByIdUrl = (id: number) => `api/tariff/${id}`;
export const createTariffPayment = (amount: number, tariff_id: number) =>
  `/api/stripe/add_payment?amount=${amount}&tariff_id=${tariff_id}`;
export const createTariffSubscription = ({
  tariff_id,
  days,
  payment_method_id,
}: ICreateSubscription) =>
  `/api/stripe/add_subscription?days=${days}&tariff_id=${tariff_id}&payment_method_id=${payment_method_id}`;
export const getCustomer = ({ user_id }: IGetStripeUser) =>
  `/api/stripe/get_customer?user_id=${user_id}`;

export const checkoutUrl = (id: number): string => `api/account/order/${id}`;
export const deleteUrl = (id: number): string => `api/user/${id}`;

export const ATTRIBUTE_COUNTRIES_URL = '/api/attribute/country_of_qualification';
export const ATTRIBUTE_EDUCATION_URL = '/api/attribute/education_degree';
export const ATTRIBUTE_SKILLS_URL = '/api/attribute/ediscovery_skills';
export const ATTRIBUTE_LANGUAGES_URL = '/api/attribute/languages';

export const PUBLICATIONS_URL = '/api/publications';
export const PUBLICATION_URL = '/api/publication';

export const createPublicationUrl = () => `${PUBLICATION_URL}`;
export const editPublicationUrl = (id: number) => `${PUBLICATION_URL}/${id}`;
export const getPublicationUrl = (id: number) => `${PUBLICATION_URL}/${id}`;
export const deletePublicationUrl = (id: number) => `${PUBLICATION_URL}/${id}`;
export const getAllPublicationsUrl = (data: { page: number; perPage: number }) =>
  `${PUBLICATIONS_URL}/list?page=${data.page}&per_page=${data.perPage}`;
export const getMyPublicationsUrl = (data: { page: number; perPage: number }) =>
  `${PUBLICATIONS_URL}/my?page=${data.page}&per_page=${data.perPage}`;
export const createPublicationLogoUrl = (id: number) => `${PUBLICATION_URL}/${id}/upload_logo`;
export const createPublicationPhoto = () => `${PUBLICATION_URL}_add`;
