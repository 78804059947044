import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { TAppActions } from '../rootDuck';
import { put, takeLatest, call } from 'redux-saga/effects';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
// import { IProduct } from '../../interfaces/product';
import {
  getJobResponses,
  changeOrderStatus,
  getMessages,
  postMessages,
} from '../../crud/jobResponses.crud';
import { IJobResponse, IMessage } from '../../interfaces/jobResponse';

const FETCH_REQUEST = 'jobResponses/FETCH_REQUEST';
const FETCH_SUCCESS = 'jobResponses/FETCH_SUCCESS';
const FETCH_FAIL = 'jobResponses/FETCH_FAIL';

const FETCH_GET_MESSAGES = 'jobResponses/FETCH_GET_MESSAGES';
const FETCH_GET_MESSAGES_SUCCESS = 'jobResponses/FETCH_GET_MESSAGES_SUCCESS';
const FETCH_GET_MESSAGES_FAIL = 'jobResponses/FETCH_GET_MESSAGES_FAIL';

const FETCH_MESSAGES = 'jobResponses/FETCH_MESSAGES';
const FETCH_MESSAGES_SUCCESS = 'jobResponses/FETCH_MESSAGES_SUCCESS';
const FETCH_MESSAGES_FAIL = 'jobResponses/FETCH_MESSAGES_FAIL';
const CLEAR = 'jobResponses/CLEAR';
const FETCH_CHECK_MESSAGES_SUCCESS = 'jobResponses/FETCH_CHECK_MESSAGES_SUCCESS';
const FETCH_CHECK_MESSAGES_FAIL = 'jobResponses/FETCH_CHECK_MESSAGES_FAIL';
const FETCH_CHECK_MESSAGES = 'jobResponses/FETCH_CHECK_MESSAGES';

const CHANGE_ORDER_STATUS_REQUEST = 'jobResponses/CHANGE_ORDER_STATUS_REQUEST';
const CHANGE_ORDER_STATUS_SUCCESS = 'jobResponses/CHANGE_ORDER_STATUS_SUCCESS';
const CHANGE_ORDER_STATUS_FAIL = 'jobResponses/CHANGE_ORDER_STATUS_FAIL';
export interface IInitialState {
  page: number;
  per_page: number;
  pageMessages: number;
  perPageMessages: number;
  total: number;
  jobResponses: IJobResponse[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;
  changeOrderStatusSuccess: boolean;
  getMessagesLoading: boolean;
  getMessagesSuccess: boolean;
  getMessagesError: string | null;
  fetchMessagesLoading: boolean;
  fetchMessagesSuccess: boolean;
  fetchMessagesError: string | null;
  messages: IMessage[] | undefined;
  checkMessages: boolean;
}

const defaultPaginatorProps = {
  page: 1,
  per_page: 20,
  total: 0,
  pageMessages: 1,
  perPageMessages: 20,
};

const initialState: IInitialState = {
  ...defaultPaginatorProps,
  jobResponses: undefined,
  loading: false,
  success: false,
  error: null,
  changeOrderStatusSuccess: false,
  getMessagesLoading: false,
  getMessagesSuccess: false,
  getMessagesError: null,
  fetchMessagesLoading: false,
  fetchMessagesSuccess: false,
  fetchMessagesError: null,
  messages: undefined,
  checkMessages: false,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'jobResponses', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CHANGE_ORDER_STATUS_REQUEST: {
        return {
          ...state,
          changeOrderStatusSuccess: false,
        };
      }
      case CHANGE_ORDER_STATUS_FAIL: {
        return {
          ...state,
          changeOrderStatusSuccess: false,
        };
      }
      case CHANGE_ORDER_STATUS_SUCCESS: {
        return {
          ...state,
          changeOrderStatusSuccess: true,
        };
      }
      case FETCH_REQUEST: {
        return {
          ...state,
          jobResponses: undefined,
          loading: true,
          success: false,
          error: null,
          page: action.payload.page,
          per_page: action.payload.perPage,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          total: action.payload.total,
          jobResponses: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_GET_MESSAGES: {
        return {
          ...state,
          messages: undefined,
          getMessagesLoading: true,
          getMessagesSuccess: false,
          getMessagesError: null,
          pageMessages: action.payload.page,
          perPageMessages: action.payload.perPage,
          fetchMessagesLoading: false,
          fetchMessagesSuccess: false,
          fetchMessagesError: null,
        };
      }

      case FETCH_GET_MESSAGES_SUCCESS: {
        return {
          ...state,
          messages: action.payload.data.reverse(),
          getMessagesSuccess: true,
          getMessagesLoading: false,
        };
      }

      case FETCH_GET_MESSAGES_FAIL: {
        return { ...state, getMessagesLoading: false, getMessagesError: action.payload };
      }

      case FETCH_MESSAGES: {
        return {
          ...state,
          fetchMessagesLoading: true,
          fetchMessagesSuccess: false,
          fetchMessagesError: null,
        };
      }

      case FETCH_MESSAGES_SUCCESS: {
        return {
          ...state,
          messages: action.payload.data.reverse(),
          fetchMessagesSuccess: true,
          fetchMessagesLoading: false,
        };
      }

      case FETCH_MESSAGES_FAIL: {
        return { ...state, fetchMessagesLoading: false, fetchMessagesError: action.payload };
      }

      case CLEAR: {
        return {
          ...state,
          getMessagesLoading: false,
          getMessagesSuccess: false,
          getMessagesError: null,
          fetchMessagesLoading: false,
          fetchMessagesSuccess: false,
          fetchMessagesError: null,
          messages: undefined,
          checkMessages: false,
        };
      }

      case FETCH_CHECK_MESSAGES: {
        return {
          ...state,
          checkMessages: true,
        };
      }

      case FETCH_CHECK_MESSAGES_SUCCESS: {
        return {
          ...state,
          checkMessages: false,
          messages: action.payload.data.reverse(),
        };
      }

      case FETCH_CHECK_MESSAGES_FAIL: {
        return {
          ...state,
          checkMessages: false,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: (payload: { page: number; perPage: number; id: string }) =>
    createAction(FETCH_REQUEST, payload),

  fetchGetMessages: (payload: { page: number; perPage: number; id: number }) =>
    createAction(FETCH_GET_MESSAGES, payload),
  fetchGetMessagesSuccess: (payload: IServerResponse<any[]>) =>
    createAction(FETCH_GET_MESSAGES_SUCCESS, payload),
  fetchGetMessagesFail: (payload: string) => createAction(FETCH_GET_MESSAGES_FAIL, payload),

  fetchMessages: (payload: { text: string; userId: number; id: number }) =>
    createAction(FETCH_MESSAGES, payload),
  fetchMessagesSuccess: (payload: IServerResponse<any[]>) =>
    createAction(FETCH_MESSAGES_SUCCESS, payload),
  fetchMessagesFail: (payload: string) => createAction(FETCH_MESSAGES_FAIL, payload),
  clear: () => createAction(CLEAR),

  fetchCheckMessages: (payload: { page: number; perPage: number; id: number }) =>
    createAction(FETCH_CHECK_MESSAGES, payload),
  fetchCheckMessagesSuccess: (payload: IServerResponse<any[]>) =>
    createAction(FETCH_CHECK_MESSAGES_SUCCESS, payload),
  fetchCheckMessagesFail: (payload: string) => createAction(FETCH_CHECK_MESSAGES_FAIL, payload),

  fetchSuccess: (payload: IServerResponse<IJobResponse[]>) => createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),
  changeOrderStatusRequest: (payload: { id: number; status: string }) =>
    createAction(CHANGE_ORDER_STATUS_REQUEST, payload),
  changeOrderStatusSuccess: (payload: IServerResponse<IJobResponse[]>) =>
    createAction(CHANGE_ORDER_STATUS_SUCCESS, payload),
  changeOrderStatusFail: (payload: string) => createAction(CHANGE_ORDER_STATUS_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga({ payload }: { payload: { page: number; perPage: number; id: string } }) {
  try {
    const { data }: { data: IServerResponse<IJobResponse[]> } = yield call(() =>
      getJobResponses(payload.page, payload.perPage, payload.id)
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchGetMessages({
  payload,
}: {
  payload: { page: number; perPage: number; id: number };
}) {
  try {
    const { data }: { data: IServerResponse<IMessage[]> } = yield call(() =>
      getMessages(payload.page, payload.perPage, payload.id)
    );
    yield put(actions.fetchGetMessagesSuccess(data));
  } catch (e) {
    yield put(actions.fetchGetMessagesFail(e?.response?.data?.message || 'Network error'));
  }
}

function* fetchMessages({ payload }: { payload: { text: string; userId: number; id: number } }) {
  try {
    let formData = new FormData();
    formData.append('order_id', payload.id.toString());
    formData.append('for_user_id', payload.userId.toString());
    formData.append('text', payload.text);
    const { data }: { data: IServerResponse<IMessage[]> } = yield call(() =>
      postMessages(formData)
    );
    yield put(actions.fetchMessagesSuccess(data));
  } catch (e) {
    yield put(actions.fetchMessagesFail(e?.response?.data?.message || 'Network error'));
  }
}

function* chandeOrderStatusSaga({ payload }: { payload: { status: string; id: number } }) {
  try {
    const { data }: { data: IServerResponse<IJobResponse[]> } = yield call(() =>
      changeOrderStatus(payload.id, payload.status)
    );
    yield put(actions.changeOrderStatusSuccess(data));
  } catch (e) {
    yield put(actions.changeOrderStatusFail(e?.response?.data?.message || 'Network error'));
  }
}

function* getNewMessages({ payload }: { payload: { page: number; perPage: number; id: number } }) {
  try {
    const { data }: { data: IServerResponse<IMessage[]> } = yield call(() =>
      getMessages(payload.page, payload.perPage, payload.id)
    );
    yield put(actions.fetchCheckMessagesSuccess(data));
  } catch (e) {
    yield put(actions.fetchCheckMessagesFail(e?.response?.data?.message || 'Network error'));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchGetMessages>>(
    FETCH_GET_MESSAGES,
    fetchGetMessages
  );
  yield takeLatest<ReturnType<typeof actions.fetchMessages>>(FETCH_MESSAGES, fetchMessages);
  yield takeLatest<ReturnType<typeof actions.changeOrderStatusRequest>>(
    CHANGE_ORDER_STATUS_REQUEST,
    chandeOrderStatusSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchCheckMessages>>(
    FETCH_CHECK_MESSAGES,
    getNewMessages
  );
}
