import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { IAppState } from '../../store/rootDuck';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    background: '#fff',
    padding: '20px 40px',
    borderRadius: '5px',
  },
}));

const ForgotPasswordEmail: React.FC<TPropsFromRedux> = ({ email }) => {
  const classes = useStyles();

  return (
    <div className="kt-login__body">
      <div className={`kt-login__form ${classes.form}`}>
        <div className="kt-login__title" >
          <h3>Вам письмо!</h3>
        </div>
        <div className="form-group">{email}</div>
      </div>
    </div>
  );
};

const connector = connect((state: IAppState) => ({
  email: state.auth.email,
}));

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ForgotPasswordEmail);
