import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    background: '#fff',
    padding: '20px 40px',
    borderRadius: '5px',
  },
}));

function EmailSentPage({ intl, match: { url } }) {
  const classes = useStyles();
  const { email } = useSelector(
    ({ auth: { emailRequested } }) => ({ email: emailRequested }),
    shallowEqual
  );

  const by =
    (url.indexOf('registration') !== -1 && 'registration') ||
    (url.indexOf('forgot') !== -1 && 'forgot');

  return (
    <div className="kt-login__body">
      <div className={`kt-login__form ${classes.form}`}>
        <div className="kt-login__title" style={{ marginTop: '1.5rem', marginBottom: '3rem' }}>
          <h3 style={{ fontWeight: 700, fontSize: '2.5rem', color: '#043655' }}>
            {intl.formatMessage({ id: 'AUTH.EMAIL.TITLE' })}
          </h3>
        </div>
        <div className="kt-login__subtitle">
          <h6 style={{ lineHeight: '20px', color: '#000' }}>
            {intl.formatMessage({ id: 'AUTH.EMAIL.DESCRIPTION' }, { name: email })}
          </h6>
        </div>
        <div className="kt-login__actions">
          <Link to={`${by === 'forgot' ? '/auth/forgot-password' : '/auth'}`}>
            <button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
              {intl.formatMessage({ id: 'AUTH.BUTTON.BACK' })}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(EmailSentPage);
